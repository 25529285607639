import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/voice-calling';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Voice Calling API`}</h1>
    <p>{`Messagebird’s Voice Calling API enables VoIP features for your web application to make, receive, and monitor voice calls from a MessageBird voice-enabled virtual mobile number to and from any country around the world through a REST API.`}</p>
    <p>{`The Voice Calling API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <p>{`Looking for a VoIP tool but not a developer? Check out `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/sip-trunking"
      }}>{`SIP Trunking`}</a>{` on your MessageBird Dashboard.`}</p>
    <p>{`If you are looking for specific documentation for a step you can directly jump to `}<a parentName="p" {...{
        "href": "/api/voice-calling/#steps"
      }}>{`callflow step documentation`}</a>{`.`}</p>
    <hr></hr>
    <h2>{`Requests`}</h2>
    <h3>{`API Endpoint`}</h3>
    <p>{`All URLs referenced in MessageBird's Voice Calling API documentation have the base URL in the code snippet on the right side:`}</p>
  <CodeSnippet code={CODE.VOICE_ENDPOINT} mdxType="CodeSnippet" />
    <h3>{`Available HTTP methods`}</h3>
    <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`), create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) or update (`}<inlineCode parentName="p">{`PUT`}</inlineCode>{`) an object. When your web application cannot do a `}<inlineCode parentName="p">{`PUT`}</inlineCode>{`, `}<inlineCode parentName="p">{`POST`}</inlineCode>{` or `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
  <CodeSnippet code={CODE.VOICE_HTTP_METHODS} mdxType="CodeSnippet" />
    <h3>{`POST/PUT`}</h3>
    <p>{`POST and PUT requests to the API should contain a JSON-formatted payload in the request body.`}</p>
    <h4>{`JSON Request Payload Example`}</h4>
  <CodeSnippet code={CODE.JSON_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Voice Calling API authentication`}</h3>
    <p>{`MessageBird's Voice Calling API uses API keys to authenticate requests.
You can view and manage your API keys in `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your MessageBird dashboard`}</a></p>
    <p>{`Test API keys have the prefix `}<inlineCode parentName="p">{`test_`}</inlineCode>{` and live API keys have the prefix `}<inlineCode parentName="p">{`live_`}</inlineCode></p>
    <p>{`With each API call, you will need to set request headers including your access key to authenticate yourself.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Header`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Authorization`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be in the form of `}<inlineCode parentName="td">{`AccessKey {accessKey}`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Errors`}</h3>
    <p>{`Codes in the 2xx range indicate that a request was successfully processed and codes in the 4xx range indicate that there was an error that resulted from the information sent (e.g. authentication, no balance or a missing or wrong parameter).`}</p>
    <p>{`In case of an error, the body of the response includes a json formatted response that tells you exactly what is wrong.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An integer that represents the error type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A human-readable description of the error. You can provide your users with this information to indicate what they can do about the error.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Error Object Example`}</h4>
  <CodeSnippet code={CODE.ERROR_OBJECT} mdxType="CodeSnippet" />
    <h3>{`Limits`}</h3>
    <p><inlineCode parentName="p">{`POST`}</inlineCode>{` and `}<inlineCode parentName="p">{`PUT`}</inlineCode>{` requests to the API should contain a JSON-formatted payload of less than 64KB in the request body.`}</p>
    <h3>{`HTTP status codes`}</h3>
    <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP status code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200 Found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested resource was found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`201 Created`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A resource has been created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`204 No Content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested resource is empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400 Bad Request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request contains invalid/missing data, or is malformed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401 Unauthorized`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The access key is missing or incorrect.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`403 Forbidden`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Access to the requested resource was forbidden.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404 Not Found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The resource could not be found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`405 Method Not Allowed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP method is not allowed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`409 Conflict`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request conflict with the current state of the server.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500 Internal Server Error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Something went wrong on our end, please try again.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Error codes (deprecated)`}</h3>
    <p>{`The following error codes have been deprecated in favor of relying on the status codes listed above.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`11`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A required parameter is missing in the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A parameter has an invalid value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`13`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested resource could not be found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`14`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Access to the requested resource was forbidden.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`15`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authentication is missing from the HTTP request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`16`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request body could not be parsed. Check if the body contents correspond with the supplied `}<inlineCode parentName="td">{`Content-Type`}</inlineCode>{` HTTP header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`18`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A query parameter has an invalid value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`21`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An internal server error on the MessageBird platform occured.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`25`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A requested action on the object can't be processed.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`Call flows`}</h2>
    <p>{`A call flow describes the flow of operations (steps) to be executed when handling an incoming call. Call flows can be assigned to multiple numbers. A number in this context is a purchased number that can be called on the traditional phone network. Each object in the steps array in a call flow describes an operation that executes during a call, e.g. transferring a call or playing back an audio file. The call flow must not exceed 32KB.`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.CALLFLOW_URI} mdxType="CodeSnippet" />
    <h4>{`Available HTTP Methods`}</h4>
  <CodeSnippet code={CODE.CALLFLOW_METHODS} mdxType="CodeSnippet" />
    <h3>{`The call flow object`}</h3>
    <p>{`This is an object representing a voice call flow at MessageBird.com.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the call flow.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`record`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Says whether a full call recording is enabled on this call flow, the default value for this attribute is `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`steps`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of step objects. The sequence of the array items describe the order of execution, where the first item will be executed first, than the second, etcetera. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#steps"
            }}>{`steps`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`default`}</inlineCode>{` attribute says whether the call flow will be used when no call flow was found for an inbound number. Only one default call flow is allowed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the call flow was created, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the call flow was last updated, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Call Flow Object Example`}</h4>
  <CodeSnippet code={CODE.CALLFLOW_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`List all call flows`}</h3>
    <p>{`This request retrieves a listing of all call flows.`}</p>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{`, `}<inlineCode parentName="p">{`_links`}</inlineCode>{` and `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` properties.`}</p>
    <p>{`If the request failed, an `}<a parentName="p" {...{
        "href": "/api/voice-calling#errors"
      }}>{`error object`}</a>{` will be returned.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`data`}</inlineCode>{` property is an array of call flow objects, can be null if there are no call flows. The `}<inlineCode parentName="p">{`_links`}</inlineCode>{` property is an object with `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/HATEOAS"
      }}>{`HATEOAS links`}</a>{` related to the object listing. The `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` property is an object with details about the result page count, total count, and pagination numbers.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.LISTCALLFLOW_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.LISTCALLFLOW_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.LISTCALLFLOW_RESPONSE} mdxType="CodeSnippet" />
    <h2>{`Viewing a call flow`}</h2>
    <p>{`This request retrieves a call flow resource.`}</p>
    <p>{`The single parameter is the unique ID that was returned upon creation.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID which was returned upon creation of a call flow.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single call flow object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.VIEWCALLFLOW_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.VIEWCALLFLOW_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.VIEWCALLFLOW_RESPONSE} mdxType="CodeSnippet" />
    <h2>{`Creating a call flow`}</h2>
    <h3>{`Using JSON`}</h3>
    <h4>{`Callflow parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`steps`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of `}<inlineCode parentName="td">{`step`}</inlineCode>{` objects.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`default`}</inlineCode>{` attribute says whether the call flow will be used when no call flow found for inbound number. `}<em parentName="td">{`Only one default call flow is allowed`}</em>{` and the default value for this attribute is `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`record`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Informs if a full call recording should be performed. If this option is set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` the call will be recorded, the default is `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single call flow object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.CREATECALLFLOW_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.CREATECALLFLOW_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.CREATECALLFLOW_RESPONSE} mdxType="CodeSnippet" />
    <h2>{`Steps`}</h2>
    <p>{`Each `}<inlineCode parentName="p">{`step`}</inlineCode>{` object describes an action that can be taken in a call flow and holds the parameters for that action.`}</p>
    <p>{`If you are looking for specific documentation for a step you can directly use the links below:`}</p>
    <ul>
      <li parentName="ul">{`say step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-say-step"
        }}>{`JSON`}</a>{`, `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-say-tag"
        }}>{`XML`}</a></li>
      <li parentName="ul">{`play step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-play-step"
        }}>{`JSON`}</a>{`, `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-play-tag"
        }}>{`XML`}</a></li>
      <li parentName="ul">{`transfer step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-transfer-step"
        }}>{`JSON`}</a>{`, `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-transfer-tag"
        }}>{`XML`}</a></li>
      <li parentName="ul">{`maskedTransfer step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-maskedtransfer-step"
        }}>{`JSON`}</a></li>
      <li parentName="ul">{`pause step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-pause-step"
        }}>{`JSON`}</a>{`, `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-pause-tag"
        }}>{`XML`}</a>{`,`}</li>
      <li parentName="ul">{`record step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-record-step"
        }}>{`JSON`}</a>{`, `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-record-tag"
        }}>{`XML`}</a></li>
      <li parentName="ul">{`fetchCallFlow step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-fetchcallflow-step"
        }}>{`JSON`}</a>{`, `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-fetchcallflow-step"
        }}>{`XML`}</a></li>
      <li parentName="ul">{`sendKeys step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-sendkeys-step"
        }}>{`JSON`}</a>{`, `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-sendkeys-tag"
        }}>{`XML`}</a></li>
      <li parentName="ul">{`hangup step `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-hangup-step"
        }}>{`JSON`}</a>{`, `}<a parentName="li" {...{
          "href": "/api/voice-calling/#the-hangup-tag"
        }}>{`XML`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/voice-calling/#answering-machine-detection-(amd)"
        }}>{`Answering Machine Detection / AMD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/voice-calling/#conditionals"
        }}>{`Conditionals`}</a></li>
    </ul>
    <h3>{`The say step`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`say`}</inlineCode>{` step pronounces a text message with a given voice and language.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`say`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressVar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the variable used for storing a keypress value when a key is pressed during this step. Setting this will trigger a default timeout of `}<inlineCode parentName="td">{`3`}</inlineCode>{` seconds after the sound is played waiting for a keypress. Variables can be used with `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressGoto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the step to jump to when the caller presses a key during this step. If `}<inlineCode parentName="td">{`maxNumKeys`}</inlineCode>{` the jump will happen only after reaching this number or `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` is reached.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set, determines which DTMF can trigger the next step (before the `}<inlineCode parentName="td">{`maxNumKeys`}</inlineCode>{` or `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` is reached). The end key is not included in the resulting variable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Options object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text to pronounce. Max length 3000 symbols`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language of the text that is to be pronounced. For a list of allowed values see `}<a parentName="td" {...{
              "href": "/api/voice-calling/#supported-languages"
            }}>{`supported languages`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`voice`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The preferred voice to use for pronouncing text. Allowed values: `}<inlineCode parentName="td">{`"male"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"female"`}</inlineCode>{`. This preference is ignored if the desired voice is not available for the selected language. See also `}<a parentName="td" {...{
              "href": "/api/voice-calling/#supported-languages"
            }}>{`supported languages`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`repeat`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount of times to repeat the payload. Allowed values: Between 1 and 10.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of seconds to wait after the say step has finished for any keypress. Only taken into account if either `}<inlineCode parentName="td">{`onKeypressVar`}</inlineCode>{` or `}<inlineCode parentName="td">{`onKeypressGoto`}</inlineCode>{` are set. Default value is `}<inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ifMachine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action to do when a machine picks up the phone. Possible values are: `}<inlineCode parentName="td">{`"continue"`}</inlineCode>{` - do not check, just play the message; `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` if a machine answers, wait until the machine stops talking; `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` hangup when a machine answers. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#answering-machine-detection-(amd)"
            }}>{`AMD`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machineTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time (in milliseconds) to analyze if a machine has picked up the phone. Used in combination with the `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` and `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` values of the `}<inlineCode parentName="td">{`"ifMachine"`}</inlineCode>{` attribute. Minimum: 400, maximum: 10000. Default: 7000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loop`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` the say step will be repeated indefinitely or until another step is executed when used in combination with the `}<inlineCode parentName="td">{`onKeypressGoto`}</inlineCode>{` parameter.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`engine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set, specifies the Text to Speech (TTS) engine that will be used to say the message. Possible TTS engines include `}<inlineCode parentName="td">{`google`}</inlineCode>{`, `}<inlineCode parentName="td">{`amazon`}</inlineCode>{` or `}<inlineCode parentName="td">{`microsoft`}</inlineCode>{`. Not all language and voice combinations are possible for each provider; for details consult the list of `}<a parentName="td" {...{
              "href": "/api/voice-calling/#supported-languages-by-engine"
            }}>{`supported languages by engine`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.CALLFLOW_SAY_STEP} mdxType="CodeSnippet" />
    <h4>{`Supported languages`}</h4>
    <p>{`The table below contains a list of locales with the options for the `}<inlineCode parentName="p">{`voice`}</inlineCode>{` attribute of the `}<inlineCode parentName="p">{`say`}</inlineCode>{` step. `}<inlineCode parentName="p">{`M`}</inlineCode>{` denotes `}<inlineCode parentName="p">{`male`}</inlineCode>{` and `}<inlineCode parentName="p">{`F`}</inlineCode>{` `}<inlineCode parentName="p">{`female`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Locale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Voices`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`af-ZA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Afrikaans (South Africa)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ar-EG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic (Egypt)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ar-SA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic (Saudi Arabia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ar-XA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`bg-BG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bulgarian (Bulgaria)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`bn-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bangla (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ca-ES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catalan (Spain)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cmn-CN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cmn-TW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (Taiwan)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cs-CZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Czech (Czechia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cy-GB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Welsh (United Kingdom)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`da-DK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Danish (Denmark)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`de-AT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Austrian German`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`de-CH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swiss High German`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`de-DE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`German (Germany)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`el-GR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Greek (Greece)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-AU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australian English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-CA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Canadian English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-GB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`British English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-GB-WLS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`British English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-IE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English (Ireland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-US`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`American English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`es-ES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`European Spanish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`es-MX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mexican Spanish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`es-US`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spanish (United States)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fi-FI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Finnish (Finland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fil-PH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filipino (Philippines)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-CA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Canadian French`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-CH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swiss French`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`French (France)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gu-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gujarati (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`he-IL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hebrew (Israel)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hi-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hindi (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hr-HR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Croatian (Croatia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hu-HU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hungarian (Hungary)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id-ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indonesian (Indonesia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`is-IS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icelandic (Iceland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`it-IT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Italian (Italy)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ja-JP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Japanese (Japan)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`jv-ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Javanese (Indonesia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`kn-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kannada (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ko-KR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Korean (South Korea)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lv-LV`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Latvian (Latvia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ml-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Malayalam (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ms-MY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Malay (Malaysia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nb-NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Norwegian Bokmål (Norway)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nl-BE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flemish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nl-NL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dutch (Netherlands)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pa-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Punjabi (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pl-PL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Polish (Poland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pt-BR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brazilian Portuguese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pt-PT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`European Portuguese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ro-RO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Romanian (Romania)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ru-RU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Russian (Russia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sk-SK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slovak (Slovakia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sl-SI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slovenian (Slovenia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sr-RS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Serbian (Serbia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sv-SE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swedish (Sweden)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ta-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tamil (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`te-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Telugu (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`th-TH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Thai (Thailand)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tr-TR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Turkish (Turkey)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`uk-UA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ukrainian (Ukraine)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`vi-VN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vietnamese (Vietnam)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`yue-HK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cantonese (Hong Kong SAR China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zh-CN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zh-HK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (Hong Kong SAR China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zh-TW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (Taiwan)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
      </tbody>
    </table>
    <Notification type="INFO" mdxType="Notification">
If a voice provider is temporarily unavailable and it is necessary, we will automatically choose another text-to-speech engine to pronounce the provided text. This could temporarily change the type of voice and character of the pronunciation.
    </Notification>
    <h4>{`Supported languages by Engine`}</h4>
    <p>{`Not all text to speech engines provide the same combinations of languages and voices. By default we will automatically select an engine if one is not specified, or will fall back to a suitable engine if the one provided in the `}<a parentName="p" {...{
        "href": "/api/voice-calling/#the-say-step"
      }}>{`Say step`}</a>{` does not have the appropriate language & voice combination. Before selecting a different engine, a different voice will be attempted first when the `}<inlineCode parentName="p">{`engine`}</inlineCode>{` option is specified.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Locale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Amazon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Microsoft`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Google`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`af-ZA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Afrikaans (South Africa)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ar-EG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic (Egypt)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ar-SA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic (Saudi Arabia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ar-XA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`bg-BG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bulgarian (Bulgaria)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`bn-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bangla (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ca-ES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catalan (Spain)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cmn-CN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cmn-TW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (Taiwan)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cs-CZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Czech (Czechia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cy-GB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Welsh (United Kingdom)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`da-DK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Danish (Denmark)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`de-AT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Austrian German`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`de-CH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swiss High German`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`de-DE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`German (Germany)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`el-GR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Greek (Greece)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-AU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australian English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-CA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Canadian English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-GB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`British English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-GB-WLS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`British English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-IE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English (Ireland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`en-US`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`American English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`es-ES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`European Spanish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`es-MX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mexican Spanish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`es-US`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spanish (United States)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fi-FI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Finnish (Finland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fil-PH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filipino (Philippines)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-CA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Canadian French`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-CH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swiss French`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`French (France)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gu-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gujarati (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`he-IL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hebrew (Israel)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hi-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hindi (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hr-HR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Croatian (Croatia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hu-HU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hungarian (Hungary)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id-ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indonesian (Indonesia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`is-IS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icelandic (Iceland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`it-IT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Italian (Italy)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ja-JP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Japanese (Japan)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`jv-ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Javanese (Indonesia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`kn-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kannada (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ko-KR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Korean (South Korea)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lv-LV`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Latvian (Latvia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ml-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Malayalam (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ms-MY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Malay (Malaysia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nb-NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Norwegian Bokmål (Norway)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nl-BE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flemish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nl-NL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dutch (Netherlands)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pa-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Punjabi (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pl-PL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Polish (Poland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pt-BR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brazilian Portuguese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pt-PT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`European Portuguese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ro-RO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Romanian (Romania)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ru-RU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Russian (Russia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sk-SK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slovak (Slovakia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sl-SI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slovenian (Slovenia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sr-RS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Serbian (Serbia)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sv-SE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swedish (Sweden)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ta-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tamil (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`te-IN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Telugu (India)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`th-TH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Thai (Thailand)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tr-TR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Turkish (Turkey)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`uk-UA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ukrainian (Ukraine)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`vi-VN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vietnamese (Vietnam)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`yue-HK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cantonese (Hong Kong SAR China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zh-CN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zh-HK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (Hong Kong SAR China)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zh-TW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (Taiwan)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M, F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`The record step`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`record`}</inlineCode>{` step initiates an audio recording during a call, e.g. for capturing a user response. The recording can be stopped by using one or more of the attributes `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{`, `}<inlineCode parentName="p">{`timeout`}</inlineCode>{`, `}<inlineCode parentName="p">{`finishOnKey`}</inlineCode>{`.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`record`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Options object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onFinish`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A URL from which a new call flow is fetched after the recording stops. The request is a `}<inlineCode parentName="td">{`POST`}</inlineCode>{` request and contains details of the recording.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onFinishTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timeout in seconds for fetching the call flow from the onFinish URL. Allowed values: Between 1 and 30. For backwards compatibility it defaults to the `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` value if set and otherwise defaults to 5 seconds.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Seconds of silence allowed before the recording is automatically stopped. If you omit this parameter, silence detection is disabled. If set, it is advised to also explicitly set `}<inlineCode parentName="td">{`onFinishTimeout`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxLength`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum length of the recording in seconds. The default is no limit on the recording length.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`finishOnKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Key DTMF input to stop the recording. Values allowed are `}<inlineCode parentName="td">{`"any", #, *, "none"`}</inlineCode>{`. The default is `}<inlineCode parentName="td">{`"none"`}</inlineCode>{`, which means that the recording will not be stopped by a DTMF.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`transcribe`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If you want to have a transcription of the recording, after the recording has finished. Default value is `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`transcribeLanguage`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language of the recording that is to be transcribed. Required when `}<inlineCode parentName="td">{`transcribe`}</inlineCode>{` is `}<inlineCode parentName="td">{`true`}</inlineCode>{`. Allowed values: `}<inlineCode parentName="td">{`"de-DE", "en-AU", "en-UK", "en-US", "es-ES", "es-LA", "fr-FR", "it-IT", "nl-NL", "pt-BR".`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ifMachine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action to do when a machine picks up the phone. Possible values are: `}<inlineCode parentName="td">{`"continue"`}</inlineCode>{` - do not check; `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` if a machine answers, wait until the machine stops talking; `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` hangup when a machine answers. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#answering-machine-detection-(amd)"
            }}>{`AMD`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machineTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time (in milliseconds) to analyze if a machine has picked up the phone. Used in combination with the `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` and `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` values of the `}<inlineCode parentName="td">{`"ifMachine"`}</inlineCode>{` attribute. Minimum: 400, maximum: 10000. Default: 7000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`beep`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether or not to play a beep when recording begins. Default is `}<inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
  <CodeSnippet code={CODE.CALLFLOW_RECORD_STEP} mdxType="CodeSnippet" />
    <h3>{`The transfer step`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`transfer`}</inlineCode>{` step transfers the call to a different phone/server. When used with the `}<inlineCode parentName="p">{`steps`}</inlineCode>{` option listed below, the transfer will be completed to the destination only after finishing those steps. For more information on this option and how to use it, see our guide on `}<a parentName="p" {...{
        "href": "/tutorials/call-screening-curl"
      }}>{`Call Screening`}</a>{`.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`transfer`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Options object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination (E.164 formatted number, SIP URI or Client URI) to transfer a call to. E.g. 31612345678, sip:`}<a parentName="td" {...{
              "href": "mailto:foobar@example.com"
            }}>{`foobar@example.com`}</a>{`, or client:name_of_client. The Client URI is in early access at this moment as part of our Client SDK.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source to override the source for the transfer leg and mask the original source. For more details see `}<a parentName="td" {...{
              "href": "/api/voice-calling/#masking-numbers"
            }}>{`Masking numbers`}</a>{`. See `}<strong parentName="td"><a parentName="strong" {...{
                "href": "/api/voice-calling#allowed-caller-ids"
              }}>{`allowed caller IDs`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mask`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true the transfer call will appear to be coming from the destination of the primary leg effectively masking the original caller/source. If the `}<inlineCode parentName="td">{`source`}</inlineCode>{` option of the transfer step is also set this value is ignored. For more details see `}<a parentName="td" {...{
              "href": "/api/voice-calling/#masking-numbers"
            }}>{`Masking numbers`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`record`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use this option if the transfer leg should be recorded. Option in is being used to record the voice of the destination and out for the source. You can use both to record both source and destination individually. Allowed values: `}<inlineCode parentName="td">{`in`}</inlineCode>{`, `}<inlineCode parentName="td">{`out`}</inlineCode>{` and `}<inlineCode parentName="td">{`both`}</inlineCode>{` options.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ifMachine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action to do when a machine picks up the phone. Possible values are: `}<inlineCode parentName="td">{`"continue"`}</inlineCode>{` - do not check; `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` if a machine answers, wait until the machine stops talking; `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` hangup when a machine answers. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#answering-machine-detection-(amd)"
            }}>{`AMD`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machineTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time (in milliseconds) to analyze if a machine has picked up the phone. Used in combination with the `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` and `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` values of the `}<inlineCode parentName="td">{`"ifMachine"`}</inlineCode>{` attribute. Minimum: 400, maximum: 10000. Default: 7000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`noAnswerTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ringing timeout for not answered call in seconds. Minimum: 20. Maximum: 90. Default: 30.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer or string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum duration of the call in seconds or as a string. Can contain units of time: e.g. 50s/10m/2h. Minimum: "30s". Maximum: "8h". Default: "8h".`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`steps`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of steps to execute after the transfer call is picked up but before connecting the parties. Applicable steps are limited to `}<inlineCode parentName="td">{`say`}</inlineCode>{`, `}<inlineCode parentName="td">{`play`}</inlineCode>{`, `}<inlineCode parentName="td">{`pause`}</inlineCode>{`, and `}<inlineCode parentName="td">{`hangup`}</inlineCode>{`. For more information consult these options and our guide on `}<a parentName="td" {...{
              "href": "/tutorials/call-screening-curl"
            }}>{`Call Screening`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
  <CodeSnippet code={CODE.CALLFLOW_TRANSFER_STEP} mdxType="CodeSnippet" />
    <h3>{`The maskedTransfer step`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`maskedTransfer`}</inlineCode>{` step is used on inbound flows to create a number-masking call. When one of the numbers in the numbers array option is matched to the calling party (source) the call is transferred to the other number in this array. However the Calling Line Identity will be replaced with the number that this flow is connected to. If there is no match no transfer will happen and the inbound call will fail. For more masking options see `}<a parentName="p" {...{
        "href": "/api/voice-calling/#masking-numbers"
      }}>{`Masking numbers`}</a>{`.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`maskedTransfer`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Options object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`numbers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of strings, where each string is an E.164 formatted number (e.g. 31612345678).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`record`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use this option if the transfer leg should be recorded. Option in is being used to record the voice of the destination and out for the source. You can use both to record both source and destination individually. Allowed values: `}<inlineCode parentName="td">{`in`}</inlineCode>{`, `}<inlineCode parentName="td">{`out`}</inlineCode>{` and `}<inlineCode parentName="td">{`both`}</inlineCode>{` options.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ifMachine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action to do when a machine picks up the phone. Possible values are: `}<inlineCode parentName="td">{`"continue"`}</inlineCode>{` - do not check; `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` if a machine answers, wait until the machine stops talking; `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` hangup when a machine answers. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#answering-machine-detection-(amd)"
            }}>{`AMD`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machineTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time (in milliseconds) to analyze if a machine has picked up the phone. Used in combination with the `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` and `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` values of the `}<inlineCode parentName="td">{`"ifMachine"`}</inlineCode>{` attribute. Minimum: 400, maximum: 10000. Default: 7000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`noAnswerTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ringing timeout for not answered call in seconds. Minimum: 20. Maximum: 90. Default: 30.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer or string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum duration of the call in seconds or as a string. Can contain units of time: e.g. 50s/10m/2h. Minimum: "30s". Maximum: "8h". Default: "8h".`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`steps`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of steps to execute after the transfer call is picked up but before connecting the parties. Applicable steps are limited to `}<inlineCode parentName="td">{`say`}</inlineCode>{`, `}<inlineCode parentName="td">{`play`}</inlineCode>{`, `}<inlineCode parentName="td">{`pause`}</inlineCode>{`, and `}<inlineCode parentName="td">{`hangup`}</inlineCode>{`. For more information consult these options and our guide on `}<a parentName="td" {...{
              "href": "/tutorials/call-screening-curl"
            }}>{`Call Screening`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
  <CodeSnippet code={CODE.CALLFLOW_MASKEDTRANSFER_STEP} mdxType="CodeSnippet" />
    <h3>{`Masking numbers`}</h3>
    <p>{`There are three ways to mask numbers for calls you place using the Voice API depending on your use case.
In all scenarios two parties `}<inlineCode parentName="p">{`A`}</inlineCode>{` and `}<inlineCode parentName="p">{`B`}</inlineCode>{` would be able to talk to each other without knowing each other's numbers but using a Proxy Number from Messagebird denoted as `}<inlineCode parentName="p">{`N`}</inlineCode>{`.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/api/voice-calling#maskedtransfer-step"
        }}>{`maskedTransfer step`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/api/voice-calling#transfer-step-with-mask-option"
        }}>{`transfer step with mask option`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/api/voice-calling#transfer-step-with-source-option"
        }}>{`transfer step with source option`}</a></li>
    </ol>
    <h4>{`maskedTransfer step`}</h4>
    <p>{`Use a `}<inlineCode parentName="p">{`maskedTransfer`}</inlineCode>{` step in the callflow of an `}<em parentName="p">{`incoming call`}</em>{` to recognize and link two parties together without exposing their numbers to each other.
e.g. If `}<inlineCode parentName="p">{`N`}</inlineCode>{` is a Proxy number you own and `}<inlineCode parentName="p">{`A`}</inlineCode>{` and `}<inlineCode parentName="p">{`B`}</inlineCode>{` are numbers that should talk to each other but remain anonymous you could achieve it by letting them both call number `}<inlineCode parentName="p">{`N`}</inlineCode>{`,
and setting the callflow of `}<inlineCode parentName="p">{`N`}</inlineCode>{` to be the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "steps": [
    {
      "action": "maskedTransfer",
      "options": {
        "numbers": ["A", "B"]
      }
    }
  ]
}
`}</code></pre>
    <p>{`In that example if `}<inlineCode parentName="p">{`A`}</inlineCode>{` calls `}<inlineCode parentName="p">{`N`}</inlineCode>{` then `}<inlineCode parentName="p">{`B`}</inlineCode>{` will receive the transferred call as coming from `}<inlineCode parentName="p">{`N`}</inlineCode>{`. Equally, if `}<inlineCode parentName="p">{`B`}</inlineCode>{` calls `}<inlineCode parentName="p">{`N`}</inlineCode>{` then `}<inlineCode parentName="p">{`A`}</inlineCode>{` would receive the call as coming from `}<inlineCode parentName="p">{`N`}</inlineCode>{`.`}</p>
    <h4>{`transfer step with mask option`}</h4>
    <p>{`Set the mask option to true for a transfer step when you want any incoming call to be forwarded to the transfer destination appearing to come from the same number that they originally dialed-in.
e.g. If `}<inlineCode parentName="p">{`N`}</inlineCode>{` is a Proxy number you own and `}<inlineCode parentName="p">{`A`}</inlineCode>{` called `}<inlineCode parentName="p">{`N`}</inlineCode>{` you can transfer the call to `}<inlineCode parentName="p">{`B`}</inlineCode>{` with both sides being anonymous by setting the callflow of `}<inlineCode parentName="p">{`N`}</inlineCode>{` to be the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "steps": [
    {
      "action": "transfer",
      "options":{
        "destination": "B"
      }
    }
  ]
}
`}</code></pre>
    <p>{`This option is similar to the `}<inlineCode parentName="p">{`maskedTransfer`}</inlineCode>{` above but behaves the same without requiring the caller's number in the callflow.
Note that this convenience method does not work well for calls originated by the API but only incoming calls.`}</p>
    <h4>{`transfer step with source option`}</h4>
    <p>{`Set the `}<inlineCode parentName="p">{`source`}</inlineCode>{` option for a transfer step when you want to have full control over how the transfer leg appears to the transfer destination.
This is the suggested way when placing a call to party `}<inlineCode parentName="p">{`A`}</inlineCode>{` and then transferring them to party `}<inlineCode parentName="p">{`B`}</inlineCode>{` but requiring anonymity of the two parties.
e.g. In the following example `}<inlineCode parentName="p">{`A`}</inlineCode>{` is receiving a call from `}<inlineCode parentName="p">{`N`}</inlineCode>{` and then gets transferred to `}<inlineCode parentName="p">{`B`}</inlineCode>{`. `}<inlineCode parentName="p">{`B`}</inlineCode>{` will see the caller of the transfer leg being `}<inlineCode parentName="p">{`C`}</inlineCode>{` (you may also opt for reusing the number `}<inlineCode parentName="p">{`N`}</inlineCode>{` as `}<inlineCode parentName="p">{`C`}</inlineCode>{`).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "destination": "A",
  "source": "N",
  "steps": [
    {
      "action": "transfer",
      "options":{
        "destination": "B",
        "source": "C"
      }
    }
  ]
}
`}</code></pre>
    <h3>{`The hangup step`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`hangup`}</inlineCode>{` step ends the call.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`hangup`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
  <CodeSnippet code={CODE.CALLFLOW_HANGUP_STEP} mdxType="CodeSnippet" />
    <h3>{`The fetchCallFlow step`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`fetchCallFlow`}</inlineCode>{` step fetches a new call flow from the supplied URL and transfers control to that flow, see `}<a parentName="p" {...{
        "href": "/api/voice-calling/#dynamic-call-flows"
      }}>{`Dynamic call flows`}</a>{`.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`fetchCallFlow`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Options object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL to fetch the new call flow from.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timeout in seconds for fetching the call flow from the url. Allowed values: Between 1 and 30. Defaults to 5.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ifMachine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action to do when a machine picks up the phone. Possible values are: `}<inlineCode parentName="td">{`"continue"`}</inlineCode>{` - do not check; `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` if a machine answers, wait until the machine stops talking; `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` hangup when a machine answers. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#answering-machine-detection-(amd)"
            }}>{`AMD`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machineTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time (in milliseconds) to analyze if a machine has picked up the phone. Used in combination with the `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` and `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` values of the `}<inlineCode parentName="td">{`"ifMachine"`}</inlineCode>{` attribute. Minimum: 400, maximum: 10000. Default: 7000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Token that will be used to sign the request to the supplied URL in order to verify it is coming from MessageBird. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#validation-of-signatures"
            }}>{`Validation of signatures`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
  <CodeSnippet code={CODE.CALLFLOW_FETCHCALLFLOW_STEP} mdxType="CodeSnippet" />
    <h4>{`The play step`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`play`}</inlineCode>{` step plays back an audio file.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`play`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressVar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the variable used for storing a keypress value when a key is pressed during this step. Setting this will trigger a default timeout of `}<inlineCode parentName="td">{`3`}</inlineCode>{` seconds after the sound is played waiting for a keypress. Variables can be used with `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressGoto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the step to jump to when the caller presses a key during this step. If `}<inlineCode parentName="td">{`maxNumKeys`}</inlineCode>{` the jump will happen only after reaching this number or `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` is reached.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set, determines which DTMF can trigger the next step (before the `}<inlineCode parentName="td">{`maxNumKeys`}</inlineCode>{` or `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` is reached). The end key is not included in the resulting variable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxNumKeys`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional limit to the number of DTMF events that should be gathered before continuing to the next step. By default, this is set to 1, so any key will trigger the next step. If `}<inlineCode parentName="td">{`EndKey`}</inlineCode>{` is set and `}<inlineCode parentName="td">{`MaxNumKeys`}</inlineCode>{` is unset, no limit for the number of keys that will be gathered will be imposed. It is possible for less keys to be gathered if the `}<inlineCode parentName="td">{`EndKey`}</inlineCode>{` is pressed or the `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` being reached.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Options object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`media`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL  of the media file to play. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#supported-media-types"
            }}>{`Supported Media Types`}</a>{` for a full list of audio files Messagebird supports. Note that the file extension can also be part of the last query parameter, so `}<inlineCode parentName="td">{`http://www.example.com/test.mp3?foo=bar&format=.mp3`}</inlineCode>{` is a valid format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loop`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` the say step will be repeated indefinitely or until another step is executed when used in combination with the `}<inlineCode parentName="td">{`onKeypressGoto`}</inlineCode>{` parameter.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ifMachine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action to do when a machine picks up the phone. Possible values are: `}<inlineCode parentName="td">{`"continue"`}</inlineCode>{` - do not check, just play the media; `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` if a machine answers, wait until the machine stops talking; `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` hangup when a machine answers. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#answering-machine-detection-(amd)"
            }}>{`AMD`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machineTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time (in milliseconds) to analyze if a machine has picked up the phone. Used in combination with the `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` and `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` values of the `}<inlineCode parentName="td">{`"ifMachine"`}</inlineCode>{` attribute. Minimum: 400, maximum: 10000. Default: 7000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of seconds to wait after the play step has finished for any keypress. Only taken into account if either `}<inlineCode parentName="td">{`onKeypressVar`}</inlineCode>{` or `}<inlineCode parentName="td">{`onKeypressGoto`}</inlineCode>{` are set. Default value is `}<inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
  <CodeSnippet code={CODE.CALLFLOW_PLAY_STEP} mdxType="CodeSnippet" />
    <h5>{`Supported Media Types`}</h5>
    <p>{`Messagebird supports media types with the following file extensions `}<inlineCode parentName="p">{`.wav`}</inlineCode>{`, `}<inlineCode parentName="p">{`.mp3`}</inlineCode>{`, `}<inlineCode parentName="p">{`.g722`}</inlineCode>{`, `}<inlineCode parentName="p">{`.gsm`}</inlineCode>{`, `}<inlineCode parentName="p">{`.alaw`}</inlineCode>{`, `}<inlineCode parentName="p">{`.al`}</inlineCode>{`, `}<inlineCode parentName="p">{`.pcm`}</inlineCode>{`, `}<inlineCode parentName="p">{`.ulaw`}</inlineCode>{`, `}<inlineCode parentName="p">{`.raw`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln12`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln16`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln24`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln32`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln44`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln48`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln96`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sln192`}</inlineCode>{`, `}<inlineCode parentName="p">{`.vox`}</inlineCode>{`.`}</p>
    <h5>{`Play Step Media Caching`}</h5>
    <p>{`By default, the audio file provided in the `}<inlineCode parentName="p">{`media`}</inlineCode>{` is cached on Messagebird's media server before being played for the user. We do this in order to ensure that the audio file is played more quickly for your users and will result in higher resilience when playing audio in the event that the source file's media server experiences latency or downtime. Messagebird's media server has a cache retention period of 7 days, however we will respect the caching policy as dictated by the source file. To make the most of (or override) Messagebird's caching, note that we preserve the following headers for the media file: `}<inlineCode parentName="p">{`Cache-Control`}</inlineCode>{`, `}<inlineCode parentName="p">{`Max-Age`}</inlineCode>{`, `}<inlineCode parentName="p">{`Etag`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Last-Modified`}</inlineCode>{`.`}</p>
    <h4>{`The pause step`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`pause`}</inlineCode>{` step pauses (silently) for a given duration.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`pause`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressVar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the variable used for storing a keypress value when a key is pressed during this step. Variables can be used with `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressGoto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the step to jump to when the caller presses a key during this step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set, determines which DTMF can trigger the next step (before the `}<inlineCode parentName="td">{`maxNumKeys`}</inlineCode>{` or `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` is reached). The end key is not included in the resulting variable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxNumKeys`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional limit to the number of DTMF events that should be gathered before continuing to the next step. By default, this is set to 1, so any key will trigger the next step. If `}<inlineCode parentName="td">{`EndKey`}</inlineCode>{` is set and `}<inlineCode parentName="td">{`MaxNumKeys`}</inlineCode>{` is unset, no limit for the number of keys that will be gathered will be imposed. It is possible for less keys to be gathered if the `}<inlineCode parentName="td">{`EndKey`}</inlineCode>{` is pressed or the `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` being reached.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Options object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`length`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer or string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The length of the pause. If an integer is given, the pause duration will be in seconds. When providing a string, the unit must be specified. Supported units include `}<inlineCode parentName="td">{`us`}</inlineCode>{`, `}<inlineCode parentName="td">{`ms`}</inlineCode>{` and `}<inlineCode parentName="td">{`s`}</inlineCode>{`. Acceptable pause durations are between 0 - 59s.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ifMachine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action to do when a machine picks up the phone. Possible values are: `}<inlineCode parentName="td">{`"continue"`}</inlineCode>{` - do not check; `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` if a machine answers, wait until the machine stops talking; `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` hangup when a machine answers. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#answering-machine-detection-(amd)"
            }}>{`AMD`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machineTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time (in milliseconds) to analyze if a machine has picked up the phone. Used in combination with the `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` and `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` values of the `}<inlineCode parentName="td">{`"ifMachine"`}</inlineCode>{` attribute. Minimum: 400, maximum: 10000. Default: 7000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
  <CodeSnippet code={CODE.CALLFLOW_PAUSE_STEP} mdxType="CodeSnippet" />
    <h3>{`The sendKeys step`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`sendKeys`}</inlineCode>{` step sends DTMF signals during a call.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique (within the call flow) identifier of the step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must be `}<inlineCode parentName="td">{`sendKeys`}</inlineCode>{` to perform this action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains zero or more key-value pairs, where the key is the identifier of the option and value is the option value. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conditions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#conditionals"
            }}>{`conditionals`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Options object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`keys`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Keys to send. Allowed set of characters: `}<inlineCode parentName="td">{`0-9, A-D, #, *`}</inlineCode>{`; with a maximum of 100 keys.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration of DTMF tone per key in milliseconds. Allowed values: Between 100 and 1000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`interval`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Interval between sending keys in milliseconds. Allowed values: Between 0 and 5000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ifMachine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action to do when a machine picks up the phone. Possible values are: `}<inlineCode parentName="td">{`"continue"`}</inlineCode>{` - do not check; `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` if a machine answers, wait until the machine stops talking; `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` hangup when a machine answers. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#answering-machine-detection-(amd)"
            }}>{`AMD`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machineTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time (in milliseconds) to analyze if a machine has picked up the phone. Used in combination with the `}<inlineCode parentName="td">{`"delay"`}</inlineCode>{` and `}<inlineCode parentName="td">{`"hangup"`}</inlineCode>{` values of the `}<inlineCode parentName="td">{`"ifMachine"`}</inlineCode>{` attribute. Minimum: 400, maximum: 10000. Default: 7000.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
  <CodeSnippet code={CODE.CALLFLOW_SENDKEYS_STEP} mdxType="CodeSnippet" />
    <h3>{`Using XML`}</h3>
    <p>{`Besides JSON, you can also create an XML based call flow. They offer the same features as JSON, but can be more readable, especially when using step conditions.`}</p>
    <p>{`To use XML when creating a call flow, the `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` HTTP header needs to be set to `}<inlineCode parentName="p">{`application/xml`}</inlineCode>{`. Upon creation, an XML call flow is parsed into a flat array of `}<inlineCode parentName="p">{`steps`}</inlineCode>{`. Any conditional structures with `}<inlineCode parentName="p">{`If`}</inlineCode>{` are ket intact.`}</p>
    <p>{`By default, steps are sequentially executed. Using the `}<inlineCode parentName="p">{`onKeypressGoto`}</inlineCode>{` attribute in tags, you can alter flow control by skipping to a specific part of the call flow.`}</p>
    <Notification type="INFO" mdxType="Notification">XML Element tags and attributes are case-sensitive. For example, using {`<play>`} instead of {`<Play>`} will give an error.</Notification>
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.IFTAG_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.IFTAG_RESPONSE} mdxType="CodeSnippet" />
    <h4>{`The Transfer tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`Transfer`}</inlineCode>{` tag transfers the call to a different phone/server.  The `}<inlineCode parentName="p">{`Transfer`}</inlineCode>{` tag may be optionally used as a container for other tags (steps) that are executed for the destination number before the transfer is complete. Applicable tags for such flows include the `}<inlineCode parentName="p">{`Play`}</inlineCode>{`, `}<inlineCode parentName="p">{`Say`}</inlineCode>{`, `}<inlineCode parentName="p">{`Hangup`}</inlineCode>{`, `}<inlineCode parentName="p">{`Pause`}</inlineCode>{` and `}<inlineCode parentName="p">{`If`}</inlineCode>{` tags. For more information on this functionality, please see our guide on `}<a parentName="p" {...{
        "href": "/tutorials/call-screening-curl"
      }}>{`Call Screening`}</a>{`.`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination (E.164 formatted number, SIP URI or Client URI) to transfer a call to. E.g. `}<inlineCode parentName="td">{`31612345678`}</inlineCode>{`, `}<inlineCode parentName="td">{`sip:foobar@example.com`}</inlineCode>{`, or `}<inlineCode parentName="td">{`client:name_of_client`}</inlineCode>{`. The Client URI is in early access at this moment as part of our Client SDK.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The Say tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`Say`}</inlineCode>{` tag pronounces a text message with a given voice and language. The XML tag content should contain the text to pronounce. The maximum amount of characters for the payload is 3000.`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language of the text that is to be pronounced. For a list of allowed values see `}<a parentName="td" {...{
              "href": "/api/voice-calling/#supported-languages"
            }}>{`supported languages`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`voice`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The voice to use for pronouncing text. Allowed values: `}<inlineCode parentName="td">{`male`}</inlineCode>{`, `}<inlineCode parentName="td">{`female`}</inlineCode>{`. Note that if the selected voice is not available in the target language, Messagebird will fall back to using the alternate available voice.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`repeat`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount of times to repeat the payload.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressVar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the variable used for storing a keypress value when a key is pressed during this step. Variables can be used in `}<inlineCode parentName="td">{`If`}</inlineCode>{` conditions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressGoto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the step to jump to when the caller presses a key during this step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set, determines which DTMF triggers the next step. The end key is not included in the resulting variable. If not set, no key will trigger the next step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxNumKeys`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional limit to the number of DTMF events that should be gathered before continuing to the next step. By default, this is set to 1, so any key will trigger the next step. If `}<inlineCode parentName="td">{`EndKey`}</inlineCode>{` is set and `}<inlineCode parentName="td">{`MaxNumKeys`}</inlineCode>{` is unset, no limit for the number of keys that will be gathered will be imposed. It is possible for less keys to be gathered if the `}<inlineCode parentName="td">{`EndKey`}</inlineCode>{` is pressed or the `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` being reached.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loop`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` the say step will be repeated indefinitely or until another step is executed when used in combination with the `}<inlineCode parentName="td">{`onKeypressGoto`}</inlineCode>{` attribute.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`engine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set, specifies the Text to Speech (TTS) engine that will be used to say the message. Possible TTS engines include `}<inlineCode parentName="td">{`google`}</inlineCode>{`, `}<inlineCode parentName="td">{`amazon`}</inlineCode>{` or `}<inlineCode parentName="td">{`microsoft`}</inlineCode>{`. Not all language and voice combinations are possible for each provider; for details consult the list of `}<a parentName="td" {...{
              "href": "/api/voice-calling/#supported-languages-by-engine"
            }}>{`supported languages by engine`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The Play tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`Play`}</inlineCode>{` tag plays back an audio file. The tag should not contain any content, only attributes.`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`media`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL  of the media file to play. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#supported-media-types"
            }}>{`Supported Media Types`}</a>{` for a full list of audio files Messagebird supports. Note that the file extension can also be part of the last query parameter, so `}<inlineCode parentName="td">{`http://www.example.com/test.mp3?foo=bar&format=.mp3`}</inlineCode>{` is a valid format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressVar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the variable used for storing a keypress value when a key is pressed during this step. Variables can be used in `}<inlineCode parentName="td">{`If`}</inlineCode>{` conditions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressGoto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the step to jump to when the caller presses a key during this step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set, determines which DTMF triggers the next step. The end key is not included in the resulting variable. If not set, no key will trigger the next step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loop`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` the say step will be repeated indefinitely or until another step is executed when used in combination with the `}<inlineCode parentName="td">{`onKeypressGoto`}</inlineCode>{` attribute.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The Pause tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`Pause`}</inlineCode>{` tag pauses (silently) for a given duration. The tag should not contain any content, only attributes.`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`length`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer or string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The length of the pause. If an integer is given, the pause duration will be in seconds. When providing a string, the unit must be specified. Supported units include `}<inlineCode parentName="td">{`us`}</inlineCode>{`, `}<inlineCode parentName="td">{`ms`}</inlineCode>{` and `}<inlineCode parentName="td">{`s`}</inlineCode>{`. Acceptable pause durations are between 0 - 59s.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressVar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the variable used for storing a keypress value when a key is pressed during this step. Variables can be used in `}<inlineCode parentName="td">{`If`}</inlineCode>{` conditions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeypressGoto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the step to jump to when the caller presses a key during this step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set, determines which DTMF triggers the next step. The end key is not included in the resulting variable. If not set, no key will trigger the next step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxNumKeys`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional limit to the number of DTMF events that should be gathered before continuing to the next step. By default, this is set to 1, so any key will trigger the next step. If `}<inlineCode parentName="td">{`EndKey`}</inlineCode>{` is set and `}<inlineCode parentName="td">{`MaxNumKeys`}</inlineCode>{` is unset, no limit for the number of keys that will be gathered will be imposed. It is possible for less keys to be gathered if the `}<inlineCode parentName="td">{`EndKey`}</inlineCode>{` is pressed or the `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` being reached.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The Record tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`Record`}</inlineCode>{` tag initiates an audio recording during a call, e.g. for capturing a user response. The tag should not contain any content, only attributes. The recording can be stopped by using one or more of the attributes `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{`, `}<inlineCode parentName="p">{`timeout`}</inlineCode>{`, `}<inlineCode parentName="p">{`finishOnKey`}</inlineCode>{`.`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxLength`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum length of a recording in seconds. The default is no limit on the recording length.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount in seconds of silence after which a recording should be stopped. Note that silence detection in noisy environments might not always provide the best results, so it is advised to also set a `}<inlineCode parentName="td">{`maxLength`}</inlineCode>{` as fallback. The default is that silence detection is disabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`finishOnKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Key DTMF input to stop recording. Values allowed are `}<inlineCode parentName="td">{`any`}</inlineCode>{`, `}<inlineCode parentName="td">{`#,`}</inlineCode>{` `}<inlineCode parentName="td">{`*,`}</inlineCode>{` `}<inlineCode parentName="td">{`none.`}</inlineCode>{` The default is `}<inlineCode parentName="td">{`none`}</inlineCode>{`, which means that a recording can not be stopped by a DTMF.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`transcribe`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` if you want to have a transcription of the recording. The default is false.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`transcribeLanguage`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`transcribe`}</inlineCode>{` is set `}<inlineCode parentName="td">{`transcribeLanguage`}</inlineCode>{` is required then. See `}<a parentName="td" {...{
              "href": "/api/voice-calling#transcriptions"
            }}>{`transcribeLanguage`}</a>{` for the list of available languages.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`beep`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether or not to play a beep when recording begins. Default is `}<inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
  <CodeSnippet code={CODE.RECORD_TAG_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`The SendKeys tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`SendKeys`}</inlineCode>{` tag sends the DTMF signals during a call.`}</p>
    <p>{`It can be used to dial a number that is reachable via an extension number or to simulate the keypresses of a physical phone to walk through an IVR system.`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`keys`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Keys to send. Allowed set of characters: `}<inlineCode parentName="td">{`0-9`}</inlineCode>{`, `}<inlineCode parentName="td">{`A-D`}</inlineCode>{`, `}<inlineCode parentName="td">{`#`}</inlineCode>{`, `}<inlineCode parentName="td">{`*`}</inlineCode>{`; with a maximum of `}<inlineCode parentName="td">{`100`}</inlineCode>{` keys.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
  <CodeSnippet code={CODE.SENDKEYS_TAG_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`The FetchCallFlow tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`FetchCallFlow`}</inlineCode>{` tag fetches a call flow from a remote URL. See: `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/voice-calling#dynamic-call-flows"
        }}>{`Dynamic call flows`}</a></strong>{`.
Any steps following this tag are ignored. The tag should not contain any content, only attributes.`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the step.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL to fetch a call flow from. Required when `}<inlineCode parentName="td">{`steps[].action`}</inlineCode>{` is `}<inlineCode parentName="td">{`fetchCallFlow`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timeout for fetching the XML/JSON callflow from the URL the user specified at the `}<inlineCode parentName="td">{`fetchCallFlow`}</inlineCode>{` action. It is optional with the default value being 5 seconds.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Token that will be used to sign the request to the supplied URL in order to verify it is coming from MessageBird. See `}<a parentName="td" {...{
              "href": "/api/voice-calling/#validation-of-signatures"
            }}>{`Validation of signatures`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The Hangup tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`Hangup`}</inlineCode>{` tag ends the call. This is an empty-element tag that has no attributes.`}</p>
    <h4>{`The If tag`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`If`}</inlineCode>{` tag is a container where the contents are other tags (steps) that are executed when the `}<inlineCode parentName="p">{`condition`}</inlineCode>{` in the condition attribute evaluates to true.`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the `}<inlineCode parentName="td">{`If`}</inlineCode>{` tag.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`condition`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An expression which will be evaluated before any nested steps are executed, e.g. `}<inlineCode parentName="td">{`foobar == 1`}</inlineCode>{`. Variables need to be stored before the `}<inlineCode parentName="td">{`If`}</inlineCode>{` tag is reached, by using the `}<inlineCode parentName="td">{`onKeypressVar`}</inlineCode>{` in an earlier step. Valid operators are `}<inlineCode parentName="td">{`==`}</inlineCode>{` and `}<inlineCode parentName="td">{`!=`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
  <CodeSnippet code={CODE.IF_TAG_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`Updating a call flow`}</h3>
    <p>{`This request updates a call flow resource. The single parameter is the unique ID that was returned upon creation.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID which was returned upon creation of a call flow.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Optional parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`steps`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of `}<inlineCode parentName="td">{`step`}</inlineCode>{` objects that replaces the current array of steps.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`record`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enables or disables full call recording.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single call flow object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.UPDATECALLFLOW_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.UPDATECALLFLOW_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.UPDATECALLFLOW_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Deleting a call flow`}</h3>
    <p>{`This request deletes a call flow. The single parameter is the unique ID that was returned upon creation.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID which was returned upon creation of a call flow.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.DELETECALLFLOW_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.DELETECALLFLOW_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Assigning numbers to a call flow`}</h3>
    <p>{`To use a call flow for incoming calls to your number(s), you'll need to assign these numbers to the callflow first.
This can be accomplished either by adding (using method POST) or replacing (using method PUT) the numbers for the callflow.
Apart from this the two endpoints will behave the same with the same request and responses.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID which was returned upon creation of a call flow.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`numbers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of strings, where each string is an E.164 formatted number (e.g. `}<inlineCode parentName="td">{`31612345678`}</inlineCode>{`) you own.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single call flow object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.ASSIGNNUMBER_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.ASSIGNNUMBER_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Listing numbers assigned to a call flow`}</h3>
    <p>{`This request retrieves a listing of all numbers currently assigned to a call flow.`}</p>
    <h4>{`Response`}</h4>
    <p>{`If successful, the request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{`, `}<inlineCode parentName="p">{`_links`}</inlineCode>{` and `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` properties. If the request failed, an error object will be returned.`}</p>
    <p>{`The data property is an array of number objects. The `}<inlineCode parentName="p">{`_links`}</inlineCode>{` property is an object with `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/HATEOAS"
      }}>{`HATEOAS links`}</a>{` related to the object listing. The `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` property is an object with details about the result page count, total count, and pagination numbers.`}</p>
    <hr></hr>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.LISTNUMBER_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.LISTNUMBER_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.LISTNUMBER_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Answering Machine Detection (AMD)`}</h3>
    <p>{`Answering Machine Detection (AMD) allows you to determine whether the receiving side of an outgoing call is detected to be a machine (voicemail or fax). Depending on the outcome of the detection you can choose to either hangup the call (`}<inlineCode parentName="p">{`ifMachine: "hangup"`}</inlineCode>{`) or to wait until the answering machine has finished talking (`}<inlineCode parentName="p">{`ifMachine: "delay"`}</inlineCode>{`) before executing any further steps.`}</p>
    <h4>{`AMD on API originated calls`}</h4>
    <p>{`To enable AMD on the first leg of your callflow (for example a call with a simple callflow of a `}<inlineCode parentName="p">{`say`}</inlineCode>{` step) you need to
add an `}<inlineCode parentName="p">{`ifMachine`}</inlineCode>{` attribute in the options of its first step. For available values see `}<a parentName="p" {...{
        "href": "/api/voice-calling/#the-call-flow-object"
      }}>{`the callflow object`}</a>{`.`}</p>
  <CodeSnippet code={CODE.OTP_AMD_SAY_HANGUP} mdxType="CodeSnippet" />
    <h4>{`AMD on transfer steps`}</h4>
    <p>{`For transfers (where the primary leg is being connected to a secondary leg towards another number) the same options can be set directly as
options of the transfer step. For available values see `}<a parentName="p" {...{
        "href": "/api/voice-calling/#the-call-flow-object"
      }}>{`the callflow object`}</a>{`.`}</p>
  <CodeSnippet code={CODE.OTP_AMD_TRANSFER_HANGUP} mdxType="CodeSnippet" />
    <Notification type="INFO" mdxType="Notification">
If the first step of the callflow happens also to be a transfer step the directions will only have effect
for the transfer step. If you wish to have AMD detection for the first leg you are advised to use an intermediate
step to host the AMD options such as a short "Pause" step.
    </Notification>
    <Notification type="WARNING" mdxType="Notification">
Answering Machine Detection doesn't come without side-effects. The algorithm works by detecting if the callee answered with an initial silence of a few seconds or started speaking for an unreasonable period without acknowledgement of the other party. Since the callflow will be delayed until a decision is made, especially for transfers that might mean that a part of the call might not be heard by both parties and could lead to some confusion.
    </Notification>
    <hr></hr>
    <h4>{`AMD events in webhook callbacks`}</h4>
    <p>{`The outcome of the Answering Machine Detection is also sent as a separate event in callbacks. For more information read the `}<a parentName="p" {...{
        "href": "/api/voice-calling#callbacks"
      }}>{`callback section`}</a>{`.`}</p>
    <h3>{`The AMDCompleteEvent`}</h3>
    <a name="AMDCompleteEvent"></a>
    <p>{`This is an object representing an AMDComplete event.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Call`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Call object corresponding to the event. See: `}<a parentName="td" {...{
              "href": "/api/voice-calling#calls"
            }}>{`call`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Leg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Leg object corresponding to the event. See: `}<a parentName="td" {...{
              "href": "/api/voice-calling#legs"
            }}>{`leg`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the AMD. Possible values: `}<inlineCode parentName="td">{`HUMAN`}</inlineCode>{`, `}<inlineCode parentName="td">{`MACHINE`}</inlineCode>{`, `}<inlineCode parentName="td">{`NOTSURE`}</inlineCode>{`,`}<inlineCode parentName="td">{`HANGUP`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Cause`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates the cause that led to the conclusion. e.g. `}<inlineCode parentName="td">{`INITIALSILENCE-2500-2500`}</inlineCode>{` if a machine was detected due to some unusual silence when starting the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the AMD occurred, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2020-08-03T15:58:41.56831062Z`}</inlineCode>{`).`}</td>
        </tr>
      </tbody>
    </table>
    <Notification type="WARNING" mdxType="Notification">
At the moment AMD configuration is limited to json callflows and XML callflows may only use the `IfMachine` attribute for Say steps.
    </Notification>
    <h3>{`Conditionals`}</h3>
    <p>{`Call flow steps can be programmed to be executed depending on which key is pressed in an IVR menu. This is a bit more straightforward and easy to code
with XML as shown in the `}<a parentName="p" {...{
        "href": "/api/voice-calling#the-if-tag"
      }}>{`if tag`}</a>{` but gets more complex when using JSON because there is no way of grouping actions together.`}</p>
    <p>{`In JSON each step can have a `}<inlineCode parentName="p">{`conditions`}</inlineCode>{` array of condition objects.`}</p>
    <h4>{`Condition object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the variable to check its value. This would be set in previous steps via the `}<inlineCode parentName="td">{`onKeypressVar`}</inlineCode>{` option of say/play/pause steps.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`operator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`==`}</inlineCode>{` for equality or `}<inlineCode parentName="td">{`!=`}</inlineCode>{` for inequality of the variable contents against contents of the value option.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The value to compare with the variable contents.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Each action that needs to be executed under specific conditions must have them defined in the `}<inlineCode parentName="p">{`conditions`}</inlineCode>{` option array.
When looking for an action to execute next, the callflow will continue with the first action that follows the last executed step
that either has all its conditions evaluated as true or has no conditions.`}</p>
    <p>{`Let's consider the following example:`}</p>
    <CodeSnippet code={CODE.CALLFLOW_CONDITIONS} mdxType="CodeSnippet" />
    <p>{`In the scenario above the user is presented with an IVR (Interactive voice response) menu. They can choose to be transfered to one of the 2 departments available.
If no key is pressed or the wrong key is pressed we'll say something different and hangup.`}</p>
    <p>{`There are a few key points to pay attention to:`}</p>
    <ul>
      <li parentName="ul">{`We use the same `}<inlineCode parentName="li">{`onKeypressVar`}</inlineCode>{` and `}<inlineCode parentName="li">{`onKeypressGoto`}</inlineCode>{` parameters on both the say and the pause so keypresses during the say step and right after are treated equally.`}</li>
      <li parentName="ul">{`Right after these two prompting steps we have a say and a hangup step to catch the case where the user did not press anything.`}</li>
      <li parentName="ul">{`The step following the hangup has the `}<inlineCode parentName="li">{`id`}</inlineCode>{` parameter set to `}<inlineCode parentName="li">{`transferdept`}</inlineCode>{` which is the same value as the `}<inlineCode parentName="li">{`onKeypressGoto`}</inlineCode>{`.
This makes the callflow skip the hangup steps if any key is pressed.`}</li>
      <li parentName="ul">{`Each step after and including the step with `}<inlineCode parentName="li">{`id`}</inlineCode>{` set to `}<inlineCode parentName="li">{`transferdept`}</inlineCode>{` has a `}<inlineCode parentName="li">{`conditions`}</inlineCode>{` array apart from the last step.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onKeypressGoto`}</inlineCode>{` does not mean that the step to go to will be executed as the step's conditions might not evaluate to true.
For example if the user pressed `}<inlineCode parentName="li">{`2`}</inlineCode>{` the steps responsible for transfering to department A will be skipped.`}</li>
      <li parentName="ul">{`If the user pressed `}<inlineCode parentName="li">{`3`}</inlineCode>{` since all conditions are strictly checking against the values `}<inlineCode parentName="li">{`1`}</inlineCode>{` and `}<inlineCode parentName="li">{`2`}</inlineCode>{` the step to be
executed will be the last one as that has no conditions set. After that the call will hangup as there are no more steps to execute.`}</li>
    </ul>
    <hr></hr>
    <h2>{`Dynamic call flows`}</h2>
    <p>{`Call flows can be statically created as described in the `}<a parentName="p" {...{
        "href": "/api/voice-calling#call-flows"
      }}>{`Creating a call flow`}</a>{`. However, you might want something `}<em parentName="p">{`dynamic`}</em>{` to happen based on business logic inside your application or organization. For example: Transfer an incoming call to a 24/7 support line when the caller is a priority customer.`}</p>
    <p>{`To achieve this, our platform can retrieve a call flow from your web service as a call is being received. Your web service can then dynamically provide the call flow to execute, based on the properties of the incoming call (e.g. the caller ID or the dialed number).`}</p>
    <Notification type="INFO" mdxType="Notification">When there is a failure during the fetching of the call-flow, for example a time-out or a wrong attribute, you can look at the <a href="https://dashboard.messagebird.com/en/voice-calls/logs" target="_blank">logs in the dashboard</a>.</Notification>
    <h3>{`Setup`}</h3>
    <p>{`First, create a call flow with a `}<inlineCode parentName="p">{`fetchCallFlow`}</inlineCode>{` step (as detailed above), passing the URL of your web service as a `}<inlineCode parentName="p">{`url`}</inlineCode>{` step option.`}</p>
    <h4>{`Example request to create a fetch callflow`}</h4>
  <CodeSnippet code={CODE.DYNAMICFLOWS_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Usage`}</h3>
    <p>{`Next, incoming calls will trigger an HTTP request to the configured URL with the following parameters:`}</p>
    <h5>{`Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1ebbae0e-e1a6-4b85-8ccb-de609d0b4510`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`31612345678`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination number that is/was called.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`firstLegDirection`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`outgoing`}</inlineCode>{`/`}<inlineCode parentName="td">{`incoming`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can be either 'incoming' or 'outgoing' and be used to distinguish whether the originating call was an incoming one or triggered via the API`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`31644556677`}</inlineCode>{`/`}<inlineCode parentName="td">{`anonymous`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The source number that triggered the call. Only owned or verified numbers can be used. See `}<strong parentName="td"><a parentName="strong" {...{
                "href": "/api/voice-calling#allowed-caller-ids"
              }}>{`allowed caller IDs`}</a></strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variables`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{"user_pressed": "5"}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Json object containing metadata of the call. Can include key presses if used together with `}<inlineCode parentName="td">{`onKeypressVar`}</inlineCode>{` in steps or via the Flow Builder.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In case the previous step is a `}<inlineCode parentName="p">{`Transfer`}</inlineCode>{` or a `}<inlineCode parentName="p">{`MaskedTransfer`}</inlineCode>{` step the `}<inlineCode parentName="p">{`variables`}</inlineCode>{` parameter will contain these additional fields:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`TransferLegID`}</inlineCode>{`: the UUID of the transfer `}<a parentName="li" {...{
          "href": "/api/voice-calling/#legs"
        }}>{`leg`}</a>{`;`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`TransferLegStatus`}</inlineCode>{`: the `}<a parentName="li" {...{
          "href": "/quickstarts/voice-calling/call-and-leg-statuses/"
        }}>{`status`}</a>{` of the transfer leg;`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`TransferLegDuration`}</inlineCode>{`: the duration in seconds of the transfer leg.`}</li>
    </ul>
    <h4>{`Example incoming request to your web service`}</h4>
  <CodeSnippet code={CODE.DYNAMICFLOWSUSAGE_REQUEST} mdxType="CodeSnippet" />
    <p>{`When receiving an incoming HTTP request, your platform should respond with either an XML or JSON call flow (see: `}<a parentName="p" {...{
        "href": "/api/voice-calling#call-flows"
      }}>{`The call flow object`}</a>{`). Additionally, the response should have a 200 OK status code, and a Content-Type HTTP header with `}<inlineCode parentName="p">{`application/xml`}</inlineCode>{` or `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` as the value, based on what type of call flow you return.`}</p>
    <h4>{`Example HTTP Response Body (XML Call Flow)`}</h4>
    <CodeSnippet code={CODE.DYNAMICFLOWSUSAGE_RESPONSE} mdxType="CodeSnippet" />
    <Notification type="INFO" mdxType="Notification">
When a statically created call flow contains a `fetchCallFlow` step, the fetched call flow will be executed and overrides the previous call flow for the call. If a call flow contained steps after a `fetchCallFlow` step, those will not be executed.
    </Notification>
    <h3>{`Validation of signatures`}</h3>
    <Notification type="WARNING" mdxType="Notification">Voice signature generation used for fetching dynamic call flows differs from <strong>both</strong> the signature generation used in <a href="/api/voice-calling#webhooks">Voice webhooks</a> <strong>and</strong> the generic signature generation used by <a href="/api#requests">other MessageBird services</a></Notification>
    <p>{`Each Dynamic Call Flow HTTP request is signed with a signature when provided with a `}<inlineCode parentName="p">{`token`}</inlineCode>{`, a base64 encoded `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://en.wikipedia.org/wiki/Hash-based_message_authentication_code"
        }}>{`HMAC`}</a></strong>{` found in the `}<inlineCode parentName="p">{`X-MessageBird-Signature`}</inlineCode>{` HTTP header. To ensure the callback is coming from the MessageBird platform, we strongly advise you to validate its signature by calculating the HMAC of the callback and base64 encoding it.`}</p>
    <p>{`Using HMAC-SHA256, the HTTP QUERY PARAMS are the `}<em parentName="p">{`message`}</em>{` and the optional `}<inlineCode parentName="p">{`token`}</inlineCode>{` of the related `}<strong parentName="p">{`fetchCallFlow`}</strong>{` step is the `}<em parentName="p">{`secret`}</em>{`.`}</p>
    <h4>{`Signature simulation example`}</h4>
    <CodeSnippet code={CODE.DYNAMIC_CALL_FLOW_SIGNING_CMD} mdxType="CodeSnippet" />
    <h4>{`Pseudocode example`}</h4>
    <CodeSnippet code={CODE.DYNAMIC_CALL_FLOW_SIGNING} mdxType="CodeSnippet" />
    <hr></hr>
    <h2>{`Calls`}</h2>
    <p>{`A call describes a voice call.`}</p>
    <p>{`A call is made to a number. A call has legs which are incoming or outgoing voice connections. An incoming leg is created when somebody calls a number. Outgoing legs are created when a call is transferred.`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.CALLS_URI} mdxType="CodeSnippet" />
    <h4>{`Available HTTP Methods`}</h4>
  <CodeSnippet code={CODE.CALLS_AND_LEGS_METHODS} mdxType="CodeSnippet" />
    <h3>{`The call object`}</h3>
    <p>{`This is an object representing a voice call at MessageBird.com.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the call. Possible values: `}<inlineCode parentName="td">{`queued`}</inlineCode>{`, `}<inlineCode parentName="td">{`starting`}</inlineCode>{`, `}<inlineCode parentName="td">{`ongoing`}</inlineCode>{`, `}<inlineCode parentName="td">{`ended`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The source number of the call, without leading `}<inlineCode parentName="td">{`+`}</inlineCode>{`, ommited if not available. Only owned or verified numbers can be used. See `}<strong parentName="td"><a parentName="strong" {...{
                "href": "/api/voice-calling#allowed-caller-ids"
              }}>{`allowed caller IDs`}</a></strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sourcePool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/numbers/#numbers-pooling"
            }}>{`Number pool name`}</a>{` to use to select a source number. Can't be specified when using `}<inlineCode parentName="td">{`source`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination number of the call, without leading `}<inlineCode parentName="td">{`+`}</inlineCode>{`, ommited if not available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`webhook.url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL to use for status callbacks. See: `}<a parentName="td" {...{
              "href": "/api/voice-calling#webhooks"
            }}>{`webhooks`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`webhook.token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The token to use for status callbacks. See: `}<a parentName="td" {...{
              "href": "/api/voice-calling#webhooks"
            }}>{`webhooks`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the call was created, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the call was last updated, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the call ended, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Call object example`}</h4>
  <CodeSnippet code={CODE.CALLOBJECT_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`List all calls`}</h3>
    <p>{`This request retrieves a listing of all calls.`}</p>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{`, `}<inlineCode parentName="p">{`_links`}</inlineCode>{` and `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` properties. If the request failed, an `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/voice-calling#errors"
        }}>{`error object`}</a></strong>{` will be returned.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`data`}</inlineCode>{` property is an array of call objects, can be null if there are no calls. The `}<inlineCode parentName="p">{`_links`}</inlineCode>{` property is an object with `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://en.wikipedia.org/wiki/HATEOAS"
        }}>{`HATEOAS`}</a></strong>{` links related to the object listing. The `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` property is an object with details about the result page count, total count, and pagination numbers.`}</p>
    <Notification type="WARNING" mdxType="Notification">
  To ensure high availability, the `totalCount` value returned from `GET /calls` may return `999999999` when determining the precise count takes more than 2 seconds to calculate. Because this pagination value may be unreliable, we recommend checking that the data property of the response object is not null when scripting requests as you can expect that this value will be null when no results have been found.
    </Notification>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.LISTCALLS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.LISTCALLS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.LISTCALLS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Viewing a call`}</h3>
    <p>{`This request retrieves a call resource. The single parameter is the unique ID that was returned upon creation.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a call generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single call object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.VIEWCALLS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.VIEWCALLS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.VIEWCALLS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Creating a call`}</h3>
    <p>{`This request initiates an `}<em parentName="p">{`outbound`}</em>{` call. When placing a call, you pass the `}<inlineCode parentName="p">{`source`}</inlineCode>{` (the caller ID), the `}<inlineCode parentName="p">{`destination`}</inlineCode>{`(the number/address that will be called), and the `}<inlineCode parentName="p">{`callFlow`}</inlineCode>{` (the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/voice-calling#call-flows"
        }}>{`call flow`}</a></strong>{` to execute when the call is answered).`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The caller ID of the call. Only owned or verified numbers can be used. See `}<strong parentName="td"><a parentName="strong" {...{
                "href": "/api/voice-calling#allowed-caller-ids"
              }}>{`allowed caller IDs`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number/address to be called. This can be an E.164 formatted number, SIP URI or Client URI. E.g. `}<inlineCode parentName="td">{`31612345678`}</inlineCode>{`, `}<inlineCode parentName="td">{`sip:foobar@example.com`}</inlineCode>{`, or `}<inlineCode parentName="td">{`client:name_of_client`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callFlow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<a parentName="td" {...{
              "href": "/api/voice-calling#call-flows"
            }}>{`call flow object`}</a>{` to be executed when the call is answered.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5>{`Optional parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callFlow.noAnswerTimeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ringing timeout for not answered call in seconds. Must be set in the callFlow object of the call. Minimum: 20. Maximum: 90. Default: 30.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callFlow.maxDuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer or string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum duration of the call in seconds or as a string. Can contain units of time: e.g. 50s/10m/2h. Minimum: "30s". Maximum: "8h". Default: "8h".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`webhook.url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL to use for status callbacks. See: `}<a parentName="td" {...{
              "href": "/api/voice-calling#webhooks"
            }}>{`webhooks`}</a>{`. Setting a webhook URL when creating a call will override any webhook resource settings if they exist.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`webhook.token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The token to use for status callbacks. See: `}<a parentName="td" {...{
              "href": "/api/voice-calling#webhooks"
            }}>{`webhooks`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single call object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.CREATECALLS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.CREATECALLS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.CREATECALLS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Updating a call`}</h3>
    <p>{`This request updates an `}<em parentName="p">{`active`}</em>{` call. You can update steps of an ongoing call by providing a new call-flow url. When you update the call through the API, the API will request the new call-flow at the by you provided new URL. Once the new call-flow is fetched, current ongoing steps will be stopped and the new call-flow will be executed.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fetchCallFlowUrl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL to fetch the new call flow from. See: `}<a parentName="td" {...{
              "href": "/api/voice-calling#dynamic-call-flows"
            }}>{`Dynamic call flows`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Optional parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fetchCallFlowToken`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The token that we will use to sign the request. See: `}<a parentName="td" {...{
              "href": "/api/voice-calling#dynamic-call-flows"
            }}>{`Dynamic call flows`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single call object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.UPDATECALLS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.UPDATECALLS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.UPDATECALLS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Deleting a call`}</h3>
    <p>{`This request will hang up all the legs of the call.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the call.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.DELETECALLS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.DELETECALLS_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Add participant to call`}</h3>
    <p>{`This request will add a new destination number to an `}<em parentName="p">{`ongoing`}</em>{` call. The new participant will receive the call, by default, with a caller ID matching the call originator's number. This can be overriden with the `}<inlineCode parentName="p">{`source`}</inlineCode>{` parameter as described below.`}</p>
    <Notification type="WARNING" mdxType="Notification">This endpoint should only be used for Inbound calls. Unexpected behavior may result for Outbound originated calls in either how the call ends, or how the remaining steps of the Call Flow proceed.</Notification>
    <h5>{`Request Parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the call.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination (E.164 formatted number, SIP URI or Client URI) to transfer a call to. E.g. 31612345678, sip:`}<a parentName="td" {...{
              "href": "mailto:foobar@example.com"
            }}>{`foobar@example.com`}</a>{`, or client:name_of_client. The Client URI is in early access at this moment as part of our Client SDK.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A source parameter can be added to the body of the request to override the Caller ID displayed for the new call participant.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.ADDPARTICIPANT_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.ADDPARTICIPANT_REQUEST} mdxType="CodeSnippet" />
    <hr></hr>
    <h2>{`Legs`}</h2>
    <p>{`A leg describes a leg object (inbound or outbound) that belongs to a call. At least one leg exists per call.`}</p>
    <p>{`Inbound legs are being created when an incoming call to a Number is being initiated.`}</p>
    <p>{`Outbound legs are created when a call is transferred or when a call is being originated from the API.`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.LEGS_URI} mdxType="CodeSnippet" />
    <h4>{`Available HTTP Methods`}</h4>
  <CodeSnippet code={CODE.LEGS_METHODS} mdxType="CodeSnippet" />
    <h3>{`The leg object`}</h3>
    <p>{`This is an object representing a leg at MessageBird.com.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the leg.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the call that this leg belongs to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number/SIP/Client URL that is making the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number/SIP/Client URL that a connection is made to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sipResponseCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If applicable, provides the final SIP status code for the leg. If not available, this field will not be returned. Possible values are listed `}<a parentName="td" {...{
              "href": "/api/voice-calling/#sip-status-codes"
            }}>{`here`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the leg. Possible values: `}<inlineCode parentName="td">{`starting`}</inlineCode>{`, `}<inlineCode parentName="td">{`ringing`}</inlineCode>{`, `}<inlineCode parentName="td">{`ongoing`}</inlineCode>{`, `}<inlineCode parentName="td">{`busy`}</inlineCode>{`, `}<inlineCode parentName="td">{`no_answer`}</inlineCode>{`, `}<inlineCode parentName="td">{`failed`}</inlineCode>{` and `}<inlineCode parentName="td">{`hangup`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The direction of the leg, indicating if it's an incoming connection or outgoing (e.g. for transferring a call). Possible values: `}<inlineCode parentName="td">{`incoming`}</inlineCode>{`, `}<inlineCode parentName="td">{`outgoing`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cost`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The cost of the leg. The amount relates to the `}<inlineCode parentName="td">{`currency`}</inlineCode>{` parameter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The three-letter currency code (ISO 4217) related to the cost of the leg.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The duration of the leg, in seconds.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the leg was created, in RFC 3339 format (e.g.`}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the leg was last updated, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`answeredAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the leg was answered, in RFC 3339 format (e.g.`}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the leg ended, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Object example`}</h4>
  <CodeSnippet code={CODE.LEGS_OBJECT} mdxType="CodeSnippet" />
    <h3>{`List all legs`}</h3>
    <p>{`This request retrieves a listing of all legs.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a call generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property containing the leg objects, can be null if there are no legs. If the request failed, an error object will be returned. The `}<inlineCode parentName="p">{`_links`}</inlineCode>{` property is an object with `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://en.wikipedia.org/wiki/HATEOAS"
        }}>{`HATEOAS`}</a></strong>{` links related to the object listing. The `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` property is an object with details about the result page count, total count, and pagination numbers.`}</p>
    <Notification type="WARNING" mdxType="Notification">
  To ensure high availability, the `totalCount` value returned from `GET /legs` may return `999999999` when determining the precise count takes more than 2 seconds to calculate. Because this pagination value may be unreliable, we recommend checking that the data property of the response object is not null when scripting requests as you can expect that this value will be null when no results have been found.
    </Notification>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.LISTLEGS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.LISTLEGS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.LISTLEGS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Viewing a leg`}</h3>
    <p>{`This request retrieves a leg resource. The parameters are the unique ID of the call and of the leg that were returned upon their respective creation.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a call generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`legId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a leg generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single call object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.VIEWLEGS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.VIEWLEGS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.VIEWLEGS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Deleting a leg`}</h3>
    <p>{`This request deletes a leg which will result in the leg being immediately disconnected. In a two-way conversation, performing this operation on any leg will hangup the call as a whole.`}</p>
    <p>{`The parameters are the unique ID of the leg.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`legId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a leg generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful or if either the call or the requested leg is already in `}<a parentName="p" {...{
        "href": "/quickstarts/voice-calling/call-and-leg-statuses/"
      }}>{`a final status`}</a>{`, this request will return an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.DELETELEG_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.DELETELEG_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Place a Leg on Hold`}</h3>
    <p>{`This request places a leg on hold. This results in the caller on that leg being placed in a state separate from the rest of the call participants, unable to hear or speak to other callers.`}</p>
    <p>{`Audio can be played to the user with the `}<inlineCode parentName="p">{`musicOnHold`}</inlineCode>{` parameter. Note that if not specified, the user will hear silence during the hold. If specified, the audio provided will be played on loop for the duration of the hold.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`legId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a leg generated upon creation.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Optional parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`musicOnHold`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Audio to play to the user on hold.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.HOLDLEG_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.HOLDLEG_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Remove a Leg from Hold`}</h3>
    <p>{`This request removes a leg from hold. This results in the caller rejoining the other call participant(s) after having been on hold.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`legId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a leg generated upon creation.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.UNHOLDLEG_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.UNHOLDLEG_REQUEST} mdxType="CodeSnippet" />
    <h3>{`SIP Status Codes`}</h3>
    <p>{`Leg objects returned from HTTP requests and via Webhooks that return a `}<inlineCode parentName="p">{`leg`}</inlineCode>{` object may contain a `}<inlineCode parentName="p">{`sipResponseCode`}</inlineCode>{` field if a SIP Status could be determined for a leg. Because this is in some situations not possible, this field may not always be returned. The following table provides an overview of the SIP statuses that MessageBird currently provides. Note that only the numerical value of the SIP Status will be returned.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`SIP Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common Cause`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode>{` - OK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Successful`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The call was connected and completed successfully.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`403`}</inlineCode>{` - Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server understood the request, but is refusing to fulfill it.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user or the network might have rejected the call. Typically by pressing the reject button on a mobile phone. Occasionally the destination number might not be allowed to be dialed from your account, for example for high-cost destinations such as premium numbers. If this occurs, please `}<a parentName="td" {...{
              "href": "https://support.messagebird.com/hc"
            }}>{`contact support`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode>{` - Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server has definitive information that the user does not exist at the domain specified in the Request-URI.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number is unallocated to a subscriber or does not exist. Please check if the number is correct and in the correct international format.†`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`408`}</inlineCode>{` - Request Timeout`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Couldn't find the user in time.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This could happen when the called party's mobile phone did not respond to the network, typically when there is no cellular / mobile signal or when the battery has died. In case your account ran out of sufficient balance to place the call this resonse code is also used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`410`}</inlineCode>{` - Gone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user existed once, but is not available here any more.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This could happen when the number has changed and is not allocated anymore.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`480`}</inlineCode>{` - Temporarily Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callee currently unavailable.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This could happen when the called party's mobile phone did not respond to the network, typically when there is no cellular / mobile signal or when the battery has died. This code may also be used if the user rejects the call, or your account has insufficient balance to place the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`484`}</inlineCode>{` - Address Incomplete`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request-URI incomplete.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number does not contain enough digits or does not exist. Please check if the number is correct and in the correct international format.†`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`486`}</inlineCode>{` - Busy Here`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callee is busy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination is valid, but the user was busy with another call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`488`}</inlineCode>{` - Not Acceptable Here`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Some aspect of the session description or the Request-URI is not acceptable.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Typically this indicates the used codecs on a SIP trunking call are not accepted, in rare cases it could also be related to either the number of the called or calling party.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode>{` - Internal Server Error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server could not fulfill the request due to some unexpected condition.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This could indicate an internal server error at one of the MessageBird servers or a downstream carrier. If this happens only for a certain number please check if the number is correct and in the correct international format.†`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`501`}</inlineCode>{` - Not Implemented`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server does not have the ability to fulfill the request, such as because it does not recognize the request method.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This error comes from a downstream carrier. If this happens only for a certain number please check if the number is correct and in the correct international format.†`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`502`}</inlineCode>{` - Bad Gateway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server is acting as a gateway or proxy, and received an invalid response from a downstream server while attempting to fulfill the request.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This error comes from a downstream carrier. If this happens only for a certain number please check if the number is correct and in the correct international format.†`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`503`}</inlineCode>{` - Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server is undergoing maintenance or is temporarily overloaded and so cannot process the request.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This could indicate unavailability of one of the MessageBird servers or of a downstream carrier. If this happens only for a certain number please check if the number is correct and in the correct international format.†`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`† When dialing internationally, it is often required that the number begins with a country code and omits any "trunk prefixes" like 0.`}</p>
    <hr></hr>
    <h2>{`Recordings`}</h2>
    <p>{`A `}<em parentName="p">{`recording`}</em>{` describes a voice recording of a leg. You can initiate a recording of a leg by having a step in your callflow with the record action set.`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.RECORDINGS_URI} mdxType="CodeSnippet" />
    <h4>{`Available HTTP Methods`}</h4>
  <CodeSnippet code={CODE.RECORDINGS_METHODS} mdxType="CodeSnippet" />
    <h3>{`The recording object`}</h3>
    <p>{`This is an object representing a recording.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the recording.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`format`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The format of the recording. Supported formats are: `}<inlineCode parentName="td">{`wav`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the recording, possible values are `}<inlineCode parentName="td">{`ivr`}</inlineCode>{`(result of a `}<inlineCode parentName="td">{`record`}</inlineCode>{` step), `}<inlineCode parentName="td">{`transfer`}</inlineCode>{`(result of a `}<inlineCode parentName="td">{`transfer`}</inlineCode>{` step recording) or `}<inlineCode parentName="td">{`call`}</inlineCode>{`(in case of full call recording).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`legId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the leg that the recording belongs to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the recording. Available statuses are:`}<inlineCode parentName="td">{`initialised`}</inlineCode>{`, `}<inlineCode parentName="td">{`recording`}</inlineCode>{`, `}<inlineCode parentName="td">{`done`}</inlineCode>{` and `}<inlineCode parentName="td">{`failed`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The duration of the recording in seconds.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the recording entry was created, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the recording entry was last updated, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`startedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the recording was started, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`). Might be null for older recordings.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`finishedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the recording was finished, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`). Might be null for older recordings.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_links`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A hash with `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/HATEOAS"
            }}>{`HATEOAS`}</a>{` links related to the object. This includes the `}<inlineCode parentName="td">{`file`}</inlineCode>{` link that has the URI for downloading the wave file of the recording.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Object example`}</h4>
  <CodeSnippet code={CODE.RECORDINGS_OBJECT} mdxType="CodeSnippet" />
    <h3>{`Viewing a recording`}</h3>
    <p>{`This request retrieves a recording resource. The parameters are the unique ID of the recording, the leg and the call with which the recording is associated.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a call generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`legId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a leg generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`recordingId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a recording generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single recording object. If the request failed, an error object will be returned. The `}<inlineCode parentName="p">{`_links`}</inlineCode>{` property is an object with `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://en.wikipedia.org/wiki/HATEOAS"
        }}>{`HATEOAS`}</a></strong>{` links related to the object listing. The `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` property is an object with details about the result page count, total count, and pagination numbers.`}</p>
    <Notification type="WARNING" mdxType="Notification">
  To ensure high availability, the `totalCount` value returned from `GET /recordings` may return `999999999` when determining the precise count takes more than 2 seconds to calculate. Because this pagination value may be unreliable, we recommend checking that the data property of the response object is not null when scripting requests as you can expect that this value will be null when no results have been found.
    </Notification>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.VIEWRECORDING_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.VIEWRECORDING_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.VIEWRECORDING_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`List recordings`}</h3>
    <p>{`To list all recordings, you can do a GET request on the `}<inlineCode parentName="p">{`/recordings`}</inlineCode>{` endpoint. The parameters are the unique ID of the recording, as well as filters detailed below.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a call generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`legId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a leg generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Filters`}</h3>
    <p>{`Besides listing all Recordings, the MessageBird Voice Recordings API also provides some filters that can be used as query parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Filter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Limit the amount of recordings listed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skip first `}<inlineCode parentName="td">{`n`}</inlineCode>{` results.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a list of recordings, which is an array. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.LISTRECORDING_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.LISTRECORDING_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Downloading the recording file`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`file`}</inlineCode>{` HATEOAS link has the appropriate URI for downloading a wave file for the recording. The file is accessible only if you provide the correct API access key for your account and the recording is for a leg/call in your account.`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.DOWNLOADRECORDING_URI} mdxType="CodeSnippet" />
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.DOWNLOADRECORDING_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.DOWNLOADRECORDING_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Possible responses`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request has succeeded. It is possible that recording file is partially served.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested recording doesn't exist.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Deleting a recording`}</h3>
    <p>{`This request deletes a recording. The parameters are the unique ID of the recording, the leg and the call with which the recording is associated.`}</p>
    <h5>{`Required parameters`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a call generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`legId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a leg generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`recordingId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a recording generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request will return an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.DELETERECORDING_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.DELETERECORDING_REQUEST} mdxType="CodeSnippet" />
    <hr></hr>
    <h2>{`Transcriptions`}</h2>
    <p>{`A `}<em parentName="p">{`transcription`}</em>{` is a textual representation of a recording as text. You can request an automated transcription for a recording by doing a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to the API.`}</p>
    <p>{`MessageBird supports several languages: `}<inlineCode parentName="p">{`de-DE`}</inlineCode>{`, `}<inlineCode parentName="p">{`en-AU`}</inlineCode>{`, `}<inlineCode parentName="p">{`en-UK`}</inlineCode>{`, `}<inlineCode parentName="p">{`en-US`}</inlineCode>{`, `}<inlineCode parentName="p">{`es-ES`}</inlineCode>{`, `}<inlineCode parentName="p">{`es-LA`}</inlineCode>{`, `}<inlineCode parentName="p">{`fr-FR`}</inlineCode>{`, `}<inlineCode parentName="p">{`it-IT`}</inlineCode>{`, `}<inlineCode parentName="p">{`nl-NL`}</inlineCode>{`, `}<inlineCode parentName="p">{`pt-BR`}</inlineCode>{`. We expect to release support for more languages soon.`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.TRANSCRIPTIONS_URI} mdxType="CodeSnippet" />
    <h4>{`Available HTTP Methods`}</h4>
  <CodeSnippet code={CODE.TRANSCRIPTIONS_METHODS} mdxType="CodeSnippet" />
    <h3>{`The transcription object`}</h3>
    <p>{`This is an object representing a transcription.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the transcription.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`recordingId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the recording that the transcription belongs to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the transcription. Possible values: created, transcribing, done, failed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the transcription was created/requested, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the transcription was last updated, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_links`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A hash with `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/HATEOAS"
            }}>{`HATEOAS`}</a>{` links related to the object. This includes the `}<inlineCode parentName="td">{`file`}</inlineCode>{` link that has the URI for downloading the text transcription of a recording.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Transcription object example`}</h4>
  <CodeSnippet code={CODE.TRANSCRIPTIONS_OBJECT} mdxType="CodeSnippet" />
    <h3>{`Creating a transcription request`}</h3>
    <p>{`In order to create a transcription request for an existing recording, you must do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to the following URI:`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.TRANSCRIPTIONS_URI} mdxType="CodeSnippet" />
    <p>{`A transcription request is asynchronous. That means that it will take some time before the result has been generated. This depends on the total duration of the recording.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`recordingId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a recording generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language of the recording that is to be transcribed. Allowed values: `}<inlineCode parentName="td">{`de-DE`}</inlineCode>{`, `}<inlineCode parentName="td">{`en-AU`}</inlineCode>{`, `}<inlineCode parentName="td">{`en-UK`}</inlineCode>{`, `}<inlineCode parentName="td">{`en-US`}</inlineCode>{`, `}<inlineCode parentName="td">{`es-ES`}</inlineCode>{`, `}<inlineCode parentName="td">{`es-LA`}</inlineCode>{`, `}<inlineCode parentName="td">{`fr-FR`}</inlineCode>{`, `}<inlineCode parentName="td">{`it-IT`}</inlineCode>{`, `}<inlineCode parentName="td">{`nl-NL`}</inlineCode>{`, `}<inlineCode parentName="td">{`pt-BR`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single transcription object. If the transcription request failed, an error object will be returned. The `}<inlineCode parentName="p">{`_links`}</inlineCode>{` property is an object with `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://en.wikipedia.org/wiki/HATEOAS"
        }}>{`HATEOAS`}</a></strong>{` links related to the object listing. The `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` property is an object with details about the result page count, total count, and pagination numbers.`}</p>
    <Notification type="WARNING" mdxType="Notification">
  To ensure high availability, the `totalCount` value returned from `GET /transcriptions` may return `999999999` when determining the precise count takes more than 2 seconds to calculate. Because this pagination value may be unreliable, we recommend checking that the data property of the response object is not null when scripting requests as you can expect that this value will be null when no results have been found.
    </Notification>
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.CREATETRANSCRIPTIONS_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Viewing a transcription`}</h3>
    <p>{`This request retrieves a transcription resource. The parameters are the unique ID of the recording, the leg and, the call with which this recording is associated.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`recordingId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of a recording generated upon creation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single transcription object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.VIEWTRANS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.VIEWTRANS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.VIEWTRANS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Downloading the transcription file`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`file`}</inlineCode>{` HATEOAS link has the appropriate URI for downloading a text file for the transcription. The file is accessible only if you provide the correct API access key for your account and the transcription is for a recording/leg/call in your account.`}</p>
    <h4>{`The format of this URI is:`}</h4>
    <h2><CodeSnippet code={CODE.DOWNLOADTRANS_URI} mdxType="CodeSnippet" /></h2>
    <h2>{`Numbers`}</h2>
    <p>{`A `}<em parentName="p">{`number`}</em>{` is a MessageBird `}<a parentName="p" {...{
        "href": "/api/numbers/"
      }}>{`number`}</a>{` purchased with the `}<inlineCode parentName="p">{`voice`}</inlineCode>{` feature. You can query voice-specific number details. The numbers can have `}<a parentName="p" {...{
        "href": "/api/voice-calling/#call-flows"
      }}>{`callflows`}</a>{` and `}<a parentName="p" {...{
        "href": "/api/voice-calling/#webhooks"
      }}>{`webhooks`}</a>{` associated with them.`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.NUMBERS_URI} mdxType="CodeSnippet" />
    <h4>{`Available HTTP Methods`}</h4>
  <CodeSnippet code={CODE.NUMBERS_METHODS} mdxType="CodeSnippet" />
    <h3>{`The number  object`}</h3>
    <p>{`This is an object representing a number. It has number and voice specific metadata`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the purchased number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callFlowId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of call flow associated if any.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the number was purchased, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2018-10-24T16:12:03Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the number was last updated, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2018-10-24T16:12:03Z`}</inlineCode>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Number  object example`}</h4>
  <CodeSnippet code={CODE.NUMBER_OBJECT} mdxType="CodeSnippet" />
    <h3>{`List all numbers`}</h3>
    <p>{`This request retrieves a listing of all numbers with the `}<inlineCode parentName="p">{`voice`}</inlineCode>{` feature.`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.NUMBERS_URI} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`If successful, the request will return an object with `}<inlineCode parentName="p">{`data`}</inlineCode>{`, `}<inlineCode parentName="p">{`_links`}</inlineCode>{` and `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` properties. If the request failed, an error object will be returned.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`data`}</inlineCode>{` property is an array of number objects. The `}<inlineCode parentName="p">{`_links`}</inlineCode>{` property is an object with `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://en.wikipedia.org/wiki/HATEOAS"
        }}>{`HATEOAS`}</a></strong>{` links related to the object listing. The `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` property is an object with details about the result page count, total count, and pagination numbers.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.LISTNUMS_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.LISTNUMS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.LISTNUMS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Get webhooks for number`}</h3>
    <p>{`This request retrieves all the webhooks associated with the number (if any).`}</p>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.GETNUMWEBHOOK_URI} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`If successful, the request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` and `}<inlineCode parentName="p">{`_links`}</inlineCode>{`. If the request failed, an error object will be returned.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`data`}</inlineCode>{` property is an array of webhook objects.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.GETNUMWEBHOOK_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.GETNUMWEBHOOK_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.GETNUMWEBHOOK_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Create webhook for number`}</h3>
    <p>{`This request creates a webhook and associates it with a purchased number if the number is purchased with the `}<inlineCode parentName="p">{`voice`}</inlineCode>{` feature enabled. There is a limit of `}<inlineCode parentName="p">{`5`}</inlineCode>{` webhooks allowed per number. Unused webhooks may be deleted via the `}<a parentName="p" {...{
        "href": "/api/voice-calling#deleting-a-webhook"
      }}>{`delete webhook endpoint`}</a>{`.`}</p>
    <h4>{`Parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL to be used for the callback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Token for authorising the URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.CREATENUMWEBHOOK_URI} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`If successful, the request will return an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` and `}<inlineCode parentName="p">{`_links`}</inlineCode>{`. If the request failed, an error object will be returned. Duplicate webhooks are silently ignored and will appear as successful.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`data`}</inlineCode>{` property is an array of webhook objects with only one object in it.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.CREATENUMWEBHOOK_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.CREATENUMWEBHOOK_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.CREATENUMWEBHOOK_RESPONSE} mdxType="CodeSnippet" />
    <hr></hr>
    <h2>{`Webhooks`}</h2>
    <p>{`Webhooks are a way of receiving event data for your calls and associated resources by your own servers.`}</p>
    <h3>{`Types of callback webhooks`}</h3>
    <p>{`There are three types of webhooks each with its own purpose.`}</p>
    <p><strong parentName="p">{` Generic webhooks `}</strong></p>
    <p>{`These webhooks are `}<em parentName="p">{`account-wide`}</em>{` webhooks and maintainable via the API endpoints described in the next section.
They can be used to receive events from all your calls, both inbound and outbound without any extra configuration.
You may create up to `}<inlineCode parentName="p">{`5`}</inlineCode>{` generic webhooks for your user account.`}</p>
    <p><strong parentName="p">{` Call webhooks `}</strong></p>
    <p>{`Call webhook is a short-lived webhook for `}<em parentName="p">{`outbound calls`}</em>{` that can be passed with the `}<a parentName="p" {...{
        "href": "/api/voice-calling#creating-a-call"
      }}>{`create call request`}</a>{` via the `}<inlineCode parentName="p">{`webhook`}</inlineCode>{` object.
If there is a call webhook set for an outbound call, that (and only that) will be used for all callbacks associated with the call so
any generic webhooks will be ignored.
Call webhooks only affect the call they are assigned to (including its legs), do not have any effect on other calls and will not appear in the API endpoints defined below.`}</p>
    <p><strong parentName="p">{` Number webhooks `}</strong></p>
    <p>{`Number webhooks are webhooks for `}<em parentName="p">{`inbound calls`}</em>{` associated with purchased numbers as described in the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/voice-calling#create-webhook-for-number"
        }}>{`numbers section`}</a></strong>{`.
They can be set once and assigned to one or multiple numbers and will trigger for all inbound calls without any other action needed.
There is a limit of `}<inlineCode parentName="p">{`5`}</inlineCode>{` number webhooks per number and that limit is seperate from the generic-webhook limit.
They will appear in the API endpoints defined below for transparency but creation still needs to happen via the `}<a parentName="p" {...{
        "href": "/api/voice-calling#numbers"
      }}>{`number endpoints`}</a>{`.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Some number webhooks might be automatically set just by using our Flowbuilder product and these are critical for the operation of your Flowbuilder flows.
    For transparency these webhooks will also appear in the API endpoints below and it is advised not to alter nor delete them.
    </Notification>
    <h3>{`The webhook object`}</h3>
    <p>{`This object represents a configuration for delivering webhooks.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the webhook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The callback URL that will be requested when our platform sends a webhook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The secret used for signing `}<a parentName="td" {...{
              "href": "/api/voice-calling#callbacks"
            }}>{`webhook requests`}</a>{`. It's suggested to be of at least 32bytes long.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(Optional) The number shown in the case of `}<a parentName="td" {...{
              "href": "/api/voice-calling#number-webhook"
            }}>{`number webhooks`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time when webhook was created, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time when webhook was updated, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2017-03-06T13:34:14Z`}</inlineCode>{`).`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Webhook object example`}</h4>
  <CodeSnippet code={CODE.WEBHOOKS_OBJECT} mdxType="CodeSnippet" />
    <h4>{`URI`}</h4>
  <CodeSnippet code={CODE.WEBHOOKS_URI} mdxType="CodeSnippet" />
    <h4>{`Available HTTP Methods`}</h4>
  <CodeSnippet code={CODE.WEBHOOKS_METHODS} mdxType="CodeSnippet" />
    <h3>{`List all webhooks`}</h3>
    <p>{`This request retrieves a listing of all webhooks.`}</p>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{`, `}<inlineCode parentName="p">{`_links`}</inlineCode>{` and `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` properties. If the request failed, an `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/voice-calling#errors"
        }}>{`error object`}</a></strong>{` will be returned.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`data`}</inlineCode>{` property is an array of webhook objects, can be null if there are no webhooks. The `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` property is an object with details about the result page count, total count, and pagination numbers.`}</p>
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.LISTWEBHOOKS_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.LISTWEBHOOKS_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Viewing a webhook`}</h3>
    <p>{`This request retrieves a webhook resource. The single parameter is the unique ID that was returned upon creation.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID which was returned upon creation of a webhook.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a data property, which is an array that has a single webhook object. If the request failed, an `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/voice-calling#errors"
        }}>{`error object`}</a></strong>{` will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.VIEWWEBHOOK_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.VIEWWEBHOOK_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.VIEWWEBHOOK_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Creating a webhook`}</h3>
    <p>{`This request creates a new webhook. There is currently a limit of `}<inlineCode parentName="p">{`5`}</inlineCode>{` (non-number) webhooks allowed per account and `}<inlineCode parentName="p">{`5`}</inlineCode>{` per number.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL to use for status callbacks. Setting a webhook URL when creating a call will override any webhook resource settings if they exist.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Optional parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The secret used for signing `}<a parentName="td" {...{
              "href": "/api/voice-calling#callbacks"
            }}>{`webhook requests`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single webhook object. If the request failed, an error object will be returned. Duplicate webhooks are silently ignored and will appear as successful.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.CREATEWEBHOOK_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.CREATEWEBHOOK_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.CREATEWEBHOOK_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Updating a webhook`}</h3>
    <p>{`This request updates a webhook resource. The single parameter is the unique ID that was returned upon creation.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that was returned upon creation of a webhook.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Optional parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The secret used for signing `}<a parentName="td" {...{
              "href": "/api/voice-calling#callbacks"
            }}>{`webhook requests`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an object with a `}<inlineCode parentName="p">{`data`}</inlineCode>{` property, which is an array that has a single webhook object. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.UPDATEWEBHOOK_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.UPDATEWEBHOOK_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
  <CodeSnippet code={CODE.UPDATEWEBHOOK_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`Deleting a webhook`}</h3>
    <p>{`This request deletes a webhook. The single parameter is the unique ID that was returned upon creation. This can also be used to delete webhooks for a number.`}</p>
    <h4>{`Required parameters`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that was returned upon creation of a webhook.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response`}</h4>
    <p>{`If successful, this request returns an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response. If the request failed, an error object will be returned.`}</p>
    <h4>{`Definition`}</h4>
  <CodeSnippet code={CODE.DELETEWEBHOOK_DEF} mdxType="CodeSnippet" />
    <h4>{`Example request`}</h4>
  <CodeSnippet code={CODE.DELETEWEBHOOK_REQUEST} mdxType="CodeSnippet" />
    <hr></hr>
    <h2>{`Callbacks`}</h2>
    <p>{`Callbacks are HTTP requests sent to your platform when a call is created or updated. A callback is triggered by a `}<em parentName="p">{`webhook`}</em>{`. To set up webhooks, please refer to the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/voice-calling#webhooks"
        }}>{`webhooks documentation`}</a></strong>{`.`}</p>
    <h3>{`Request`}</h3>
    <p>{`Each HTTP request has a body with a JSON object of the created/updated resource.`}</p>
    <h4>{`HTTP body`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timestamp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date-time the callback was created, in RFC 3339 format (e.g. `}<inlineCode parentName="td">{`2020-08-03T15:58:41.56831062Z`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of items that triggered a callback. When sending callbacks, our platform might batch items, where each item will be an object in this array.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the resource that got created/updated. e.g. `}<inlineCode parentName="td">{`call`}</inlineCode>{`, `}<inlineCode parentName="td">{`leg`}</inlineCode>{`,`}<inlineCode parentName="td">{`transcription`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The event that triggered the callback. e.g. `}<inlineCode parentName="td">{`callCreated`}</inlineCode>{`, `}<inlineCode parentName="td">{`callUpdated`}</inlineCode>{`,`}<inlineCode parentName="td">{`amdComplete`}</inlineCode>{`. Optional`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].payload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The resource related to the event.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Callback events`}</h3>
    <p>{`Callbacks are sent for multiple types of events and the items in the callback body may vary per type. Web callbacks are currently supported for the following:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Event Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Events`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Payload`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`callCreated,callUpdated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/voice-calling#calls"
            }}>{`call`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/voice-calling#legs"
            }}>{`leg`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AMDCompleteEvent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`amdComplete`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/voice-calling#AMDCompleteEvent"
            }}>{`amdComplete`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`recording`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`recordingStarted,recordingUpdated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/voice-calling#recordings"
            }}>{`recording`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`transcription`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`transcriptionCreated,transcriptionFinished,transcriptionTranscribing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/voice-calling#transcriptions"
            }}>{`transcription`}</a></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Response`}</h3>
    <p>{`Your platform should respond with a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` HTTP header.`}</p>
    <h4>{`Example request (Type call)`}</h4>
  <CodeSnippet code={CODE.HANDLEWEBHOOKCALL_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example request (Type leg)`}</h4>
  <CodeSnippet code={CODE.HANDLEWEBHOOKLEG_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example request (Type AMDCompleteEvent)`}</h4>
    <p>{`AMD events will mainly have a status and a cause.`}</p>
    <p>{`The status can have the values: `}<inlineCode parentName="p">{`MACHINE`}</inlineCode>{`,`}<inlineCode parentName="p">{`HUMAN`}</inlineCode>{`,`}<inlineCode parentName="p">{`NOTSURE`}</inlineCode>{`,`}<inlineCode parentName="p">{`HANGUP`}</inlineCode>{` and the cause will give more information about the
detection. e.g. `}<inlineCode parentName="p">{`INITIALSILENCE-2500-2500`}</inlineCode>{` if a machine was detected due to some unusual silence when starting the call.`}</p>
  <CodeSnippet code={CODE.HANDLEWEBHOOK_AMD_REQUEST} mdxType="CodeSnippet" />
    <Notification type="INFO" mdxType="Notification">
It is suggested not to depend on the completeness of the data received in the callbacks as some fields' availability may vary depending on the type and state of call. For example legs will not always have a duration nor cost and some other fields like answeredAt might be null.
For AMD events specifically the call and leg data might not be fully synchronised with the actual state of the call and is provided mainly matching with other call and leg data via the call and leg ID's.
    </Notification>
    <h3>{`Validation of signatures`}</h3>
    <Notification type="WARNING" mdxType="Notification">Voice webhook/callback signature generation used in POST HTTP requests differs from <strong>both</strong> the signature generation used for fetching <a href="/api/voice-calling#dynamic-call-flows">Dynamic call flows</a> <strong>and</strong> the generic signature generation used by <a href="/api#requests">other MessageBird services</a></Notification>
    <p>{`Each Webhook callback HTTP request is signed with a signature, a base64 encoded `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://en.wikipedia.org/wiki/Hash-based_message_authentication_code"
        }}>{`HMAC`}</a></strong>{` found in the `}<inlineCode parentName="p">{`X-MessageBird-Signature`}</inlineCode>{` HTTP header. To ensure the callback is coming from the MessageBird platform, we strongly advise you to validate its signature by calculating the binary HMAC of the callback and base64 encoding it.`}</p>
    <p>{`Using HMAC-SHA256, the HTTP body is the `}<em parentName="p">{`message`}</em>{` and the `}<inlineCode parentName="p">{`token`}</inlineCode>{` of the related `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/voice-calling#webhooks"
        }}>{`webhook`}</a></strong>{` resource is the `}<em parentName="p">{`secret`}</em>{`. Only handle the webhook if the computed value matches the signature in the HTTP header.`}</p>
    <h4>{`Signature simulation example`}</h4>
    <CodeSnippet code={CODE.DYNAMIC_CALL_FLOW_SIGNING_CMD} mdxType="CodeSnippet" />
    <h4>{`Pseudocode example`}</h4>
    <CodeSnippet code={CODE.HANDLEWEBHOOKLEG_SIGNING} mdxType="CodeSnippet" />
    <h3>{`Allowed Caller IDs`}</h3>
    <p>{`Only phone numbers which are owned or verified can be used as a caller ID.`}</p>
    <p>{`An owned number is a MessageBird-hosted number that you bought via your account.`}</p>
    <p>{`A verified number is non-MessageBird-hosted number which is verified in your account. You can find more about verified numbers in the `}<a href="https://support.messagebird.com/hc/en-us/articles/4412586921233">{`article`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      